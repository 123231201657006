import { Box, Button, RadioGroup } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Card from "components/card/Card";
import { useForm } from "react-hook-form";
import { InputField } from "components/inputField/input";
import { GenderField } from "components/genderField/gender";

const defaultValues = {
  name: "",
  nickName: "",
  address: "",
  birthday: "",
  gender: "",
  homeTown: "",
};

export default function FamilyDetail({
  handleCreate,
  handleUpdate,
  editMember,
  isEditing,
  setIsEditing,
  family,
  ownerInformation
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (isEditing && editMember) {
      reset(defaultValues);
      Object.keys(editMember).forEach((key) => {
        if (key === "birthday" && editMember[key]) {
          // Chuyển đổi định dạng ngày sinh
          const year = editMember.birthday;
          // const day = String(date.getDate()).padStart(2, '0');
          // const month = String(date.getMonth() + 1).padStart(2, '0');
          // const year = date.getFullYear();
          const formattedDate = `${year}`;
          setValue(key, formattedDate);
        } else {
          setValue(key, editMember[key]);
        }

        // setValue(key, editMember[key]);
      });
    } else {
      reset(defaultValues);
    }
  }, [isEditing, editMember, reset, setValue]);

  const onSubmit = async (formData) => {
    setLoading(true);
    const memberData = {
      ...formData,
      homeTown: editMember?._id === family?.onwerId ? formData?.homeTown : ownerInformation?.[0]?.homeTown,
      address: editMember?._id === family?.onwerId ? formData?.address : ownerInformation?.[0]?.address,
      // birthday: formData.birthday.split('/').reverse().join('-'),
    };

    if (isEditing) {
      await handleUpdate(memberData);
    } else {
      await handleCreate(memberData);
    }
    reset(defaultValues);
    setIsEditing(false);
    setShowPopup(false);
    setLoading(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    reset(defaultValues);
    setShowPopup(false);
  };

  const handleOverlayClick = (e) => {
    // Đóng khung nếu click bên ngoài ô đen
    if (e.target.classList.contains("overlay")) {
      setShowPopup(false);
    }
  };

  return (
    <div className="flex justify-between flex-wrap mt-10">
      {showPopup || isEditing ? (
        <>
          <Box
            className="overlay fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={handleOverlayClick}
          ></Box>

          <Box
            className="popup fixed bottom-10 left-1/2 transform -translate-x-1/2 z-50 bg-white p-5 rounded-lg shadow-md overflow-y-scroll h-5/6 w-full min-w-xl transition-transform duration-300"
            width={{
              base: "90%",
              xl: "60%",
            }}
          >
            <Card width="100%">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-12 mt-2">
                  <div className="border-b border-gray-900/10 pb-6">
                    <Box className="flex w-full justify-between">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        {isEditing ? "Sửa thành viên" : "Thêm thành viên"}
                      </h2>
                    </Box>
                    <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-5">
                      <InputField
                        label="Họ và tên:"
                        name="name"
                        register={register}
                        errors={errors}
                        required
                      />
                      <RadioGroup defaultValue={editMember?.gender || ""}>
                        <GenderField
                          register={register}
                          errors={errors}
                          defaultValue={editMember?.gender || ""}
                          required
                        />
                      </RadioGroup>
                      <InputField
                        label="Pháp danh:"
                        name="nickName"
                        register={register}
                        errors={errors}
                        required
                      />
                      <InputField
                        label="Năm sinh:"
                        name="birthday"
                        register={register}
                        errors={errors}
                        required
                      />
                      {editMember?._id === family?.onwerId && (
                        <>
                          <InputField
                            label="Nguyên quán:"
                            name="homeTown"
                            register={register}
                            errors={errors}
                            required
                          />
                          <InputField
                            label="Hiện trú:"
                            name="address"
                            register={register}
                            errors={errors}
                            required
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex items-center justify-end gap-x-6">
                  <Button onClick={handleCancel} variant="ghost">
                    {isEditing ? "Hủy cập nhật" : "Hủy"}
                  </Button>
                  <Button
                    type="submit"
                    bgColor="#4834d4"
                    color="white"
                    _hover={{
                      color: "#4834d4",
                      bgColor: "white",
                      border: "0.5px solid #4834d4",
                    }}
                    borderRadius="5px"
                    isLoading={loading}
                  >
                    {isEditing ? "Cập nhật" : "Thêm"}
                  </Button>
                </div>
              </form>
            </Card>
          </Box>
        </>
      ) : (
        <div className="flex flex-col justify-end">
          <Button
            bgColor="#4834d4"
            color="white"
            _hover={{
              color: "#4834d4",
              bgColor: "white",
              border: "0.5px solid #4834d4",
            }}
            onClick={() => setShowPopup(true)}
            borderRadius="5px"
          >
            Thêm thành viên
          </Button>
        </div>
      )}
    </div>
  );
}
