import { Box, Button } from '@chakra-ui/react'
import React from 'react'
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LOGOUT_SUCCESSFULLY } from 'utils/constants';

const Logout = () => {
  const history = useHistory();

  const handleLogout = async() => {
    const {isConfirmed} = await Swal.fire({
      title: "Bạn có muốn đăng xuất?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Xác nhận',
      cancelButtonText: 'Hủy',
      backdrop: `
      rgba(0,0,123,0.4)
      left top
      no-repeat
      `,
    });
    if (isConfirmed){
      localStorage.removeItem('@auth')
      localStorage.removeItem('pagodaId')
      localStorage.removeItem('role')
      NotificationManager.success(LOGOUT_SUCCESSFULLY)
      history.push('/login');
    }
  }

  return (
    <Box>
      <Button _hover={{textShadow: '0 0 1.5px #2980b9'}} borderRadius='5px' border='1px solid red' color='red' fontSize='md' px='55px' onClick={handleLogout}>Đăng xuất</Button>
    </Box>
  )
}

export default Logout
