import { Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { GENDER_FIELD } from "utils/constants";

// Chọn giới tính
export const GenderField = ({ register, errors, defaultValue, required }) => {

  const [selectedGender, setSelectedGender] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setSelectedGender(defaultValue);
    } else {
      setSelectedGender(''); 
    }
  }, [defaultValue]);

  const handleGenderChange = (value) => {
    setSelectedGender(value);
  };

  return (
    <RadioGroup className="sm:col-span-2" 
      value={selectedGender}
      onChange={(value) => handleGenderChange(value)}
    >
      <Stack spacing={4} direction="column">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Giới tính: {required && <span className="text-red-500">*</span>}
        </label>
        <Stack spacing={2} direction="row">
          <Radio
            {...register('gender', { required: GENDER_FIELD })}
            id="gender-male"
            value="male"
            colorScheme="blue"
            fontSize='sm'
            mr='3'
          >
            Nam
          </Radio>
          <Radio
            {...register('gender', { required: GENDER_FIELD })}
            id="gender-female"
            value="female"
            colorScheme="pink"
            fontSize='sm'
          >
            Nữ
          </Radio>
        </Stack>
        {errors.gender && (
          <Text fontSize="sm" color="red.500">
            {errors.gender.message}
          </Text>
        )}
      </Stack>
    </RadioGroup>
  );
}