
import { postTakenData, deleteTakenData, getTakenData, patchTakenData, putTakenData } from "utils/api";
import { ERROR_SERVER } from "utils/constants";
/// FAMILY
//lấy dữ liệu

export const handleGetFamiles = async (pagodaId, page, limit, searchQuery) => {
  
  try {
    const [familyResponse, membersResponse] = await Promise.all([
      getTakenData(`/api/v1/family?name=${searchQuery}&page=${page}&limit=${limit}`, { pagodaId }),
      getTakenData(`/api/v1/members`, { pagodaId })
      ]);
    
    return {
      familyData: familyResponse.data.items || [],
      totalPages: familyResponse.data.totalPages || [],
      membersData: membersResponse.data.items || [],
    };
  } catch (error) {
     console.error(ERROR_SERVER);
    
  }
};

//tạo thành viên đầu tiên ở trang families sẽ mặc định là owner
export const handleCreateFamily = async (newMember, pagodaId) => {
  try {
    let member = {};

    // Nếu có _id từ clipboard, sử dụng thông tin này
    if (newMember._id) {
      member = newMember;
    } else {
      // Nếu không có _id từ clipboard, tạo mới thành viên
      const memberRes = await postTakenData(`/api/v1/members`, { ...newMember, pagodaId });
      member = memberRes.data;
    }

    // Thêm thành viên vào gia đình
    if (member._id) {
      const familyRes = await postTakenData(`/api/v1/family/${member._id}`, { pagodaId });

      const family = familyRes.data;

      await patchTakenData(`/api/v1/family/${family._id}/add-family/${member._id}`);

      return { family, member };
    } else {
      return null;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = error.response;
      const { message } = data;
      console.error('Lỗi 400:', message);
      throw error;
    } else {
      console.error(ERROR_SERVER, error.message);
      throw error;
    }
  }
};


// xóa gia đình
export const handleDeleteFamily = async (familyId, pagodaId) => {
  try {
    const deleteFamilyRes = await deleteTakenData(`/api/v1/family/${familyId}`, { pagodaId });

    return deleteFamilyRes;

  } catch (error) {
     console.error(ERROR_SERVER);
  }
};


/// END FAMILY


/// MEMBERS
// lấy dữ liệu
export const handleGetFamilyAndMembers = async (familyId, pagodaId) => {
  try {
    const [familyRes, membersRes] = await Promise.all([
      getTakenData(`/api/v1/family/${familyId}`, {pagodaId}),
      getTakenData(`/api/v1/members`, {pagodaId})
    ]);

    const familyData = familyRes.data;

    if (!Array.isArray(familyData.membersId)) {
      familyData.membersId = [];
    }

    return { family: familyData, members: membersRes.data?.items };
  } catch (error) {
     console.error(ERROR_SERVER);
  }
};

//show năm cùng con giáp lên ui
export const handleGetYearBirthday = async (familyId) => {
  try {

    const birthdayZodiac = await getTakenData(`/api/v1/family/members-zodiac/${familyId}`)
 

    const membersZodiacs = birthdayZodiac?.data?.members

    return membersZodiacs

  } catch (error) {
    console.error(ERROR_SERVER, error);
  }
};

// tạo thành viên
export const handleCreateMember = async (newMember, familyId, pagodaId) => {

  try {
    let memberId;

    if (newMember._id) {
      // Nếu có _id, sử dụng _id để thêm thành viên vào gia đình
      memberId = newMember._id;
    } else {
      // Nếu không có _id, tạo thành viên mới
      const memberRes = await postTakenData(`/api/v1/members`, { ...newMember, pagodaId });
      memberId = memberRes.data._id;
    }

    // Thêm thành viên vào gia đình 
    await patchTakenData(`/api/v1/family/${familyId}/add-family/${memberId}`);

    return { member: newMember  };

  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = error.response;
      const { message } = data;
      console.error('Lỗi 400:', message);
      throw error;
    } else {
      console.error(ERROR_SERVER, error.message);
      throw error;
    }
  }
};

// cập nhật thành viên
export const handleUpdateMember = async (updatedMember, familyId, family) => {
  try {

      const { __v, _id, createdAt, updatedAt, ...requestData } = updatedMember;
      const res = await putTakenData(`/api/v1/members/${updatedMember._id}`, requestData);
        
      if (updatedMember._id === family.onwerId) {
        await putTakenData(`/api/v1/family/updateNameOwner/${familyId}?idOwner=${updatedMember._id}`, familyId);
      }

      return res.data;

  } catch (error) {
    if (error.response && error.response.status === 409) {
      const { data } = error.response;
      const { message } = data;
      console.error('Lỗi 409:', message);
      throw error;
    } else {
      console.error(ERROR_SERVER, error.message);
      throw error;
    }
  }
};

// xóa thành viên
export const handleDeleteMember = async (memberId, family) => {
  try {
      const memberDelete = await deleteTakenData(`/api/v1/members/${memberId}`);
      await patchTakenData(`/api/v1/family/${family._id}/remove-member/${memberId}`);

      return memberDelete ;
  } catch (error) {
     console.error(ERROR_SERVER);

  }
};

// cập nhật , thay đổi ai là chủ bái
export const handleUpdateOwner = async (newOwnerId, currentOwnerId, memberIdToSwitch, familyId, pagodaId) => {

  try {

    const requestData = {
      onwerId: newOwnerId, 
      pagodaId
    };

    const isOwner = await putTakenData(`/api/v1/family/${familyId}`, requestData);

    //sửa ngày 22/07/2024
    await putTakenData(`/api/v1/family/updateNameOwner/${familyId}?idOwner=${newOwnerId}`, familyId);
    //end sửa ngày 22/07/2024

    return isOwner;

  } catch (error) {
     console.error(ERROR_SERVER);

  }
};

/// END MEMBERS
