import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from 'layouts/admin';
import UserLayout from 'layouts/user';
import Login from '../src/views/auth/login';
import ForgotPassword from 'views/auth/forgotPassword';
import { useAuth } from 'contexts/AuthContext';
import { Box, Spinner, Text } from '@chakra-ui/react';
import NotFound from 'views/errorNotFound/notFound';
import { handleChoosePagodas } from 'apis/admin';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { NotificationManager } from 'react-notifications';
import { PAGODA_NOT_EXIST } from 'utils/constants';

const App = () => {

  const { auth, loading, setAuth  } = useAuth();
  const { accessToken, role, pagodaId } = auth;
  const [pagodaStillExists, setPagodaStillExists] = useState(true);
  const history = useHistory()

  const handleLogout = useCallback(() => {

    localStorage.removeItem('@auth');
    localStorage.removeItem('pagodaId');
    localStorage.removeItem('role');
    setAuth({ accessToken: null, role: null, pagodaId: null });
    NotificationManager.error(PAGODA_NOT_EXIST);
    history.push('/login');

  }, [setAuth, history]);
  
  // nếu chùa bị xóa thì logout
  useEffect(() => {
    const handlePagodaDeleted = async () => {
      try {
        if (pagodaId) {
            const updatedPagodas = await handleChoosePagodas();
            setPagodaStillExists(updatedPagodas.some(p => p._id === pagodaId));
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Lỗi 403:', error.response.data.message);
          handleLogout();
        } else {
          console.error(error.message);
          throw error;
        }
      }
    };

      handlePagodaDeleted();

  }, [pagodaId, setAuth]);

  useEffect(() => {
    if (!pagodaStillExists) {
      handleLogout();
    }
  }, [pagodaStillExists]);

  if (loading) {
    return (
      <Box className="relative">
        <Box className="absolute flex justify-center items-center gap-2 top-80 left-[45%]">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='lg'
          /> <Text>Đang tải dữ liệu...</Text> 
        </Box>
      </Box>
    ) 
  }
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      
      {accessToken ? (
        role === 'admin' ? (
          <Route path="/admin" component={AdminLayout} />
        ) : (
          <Route path="/user" component={UserLayout} />
        )
      ) : (
        <Redirect to="/login" />
      )}
      <Redirect exact from="/" to={accessToken ? (role === 'admin' ? "/admin" : "/user") : "/login"} />
      
      {/* Chuyển hướng mọi route không khớp đến /not-found */}
      <Route path="/not-found" component={NotFound} />
      <Redirect from="*" to="/not-found" />
    </Switch>
  );
};

export default App;
