import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  // MdBarChart,

  MdHome,
  MdPeople 
} from "react-icons/md";
import { FaFileWord } from "react-icons/fa";
//admin
import Pagoda from "views/admin/pagoda";
import AddUser from "views/admin/addUser";
import Families from "views/admin/families";
import FamiliesDetail from "views/admin/familyDetail";
import PrintFamily from "views/printFamily/print";

//user
import Family from "views/user/family";
import FamilyDetail from "views/user/familyDetail";


// admin
export const routesAdmin = [
  {
    name: "Danh Sách Chùa",
    layout: "/admin",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    path: "/pagodas",
    component: Pagoda,
  },
  {
    name: "Danh Sách Sư Quản Lí",
    layout: "/admin",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    path: "/staffs/staff-of-pagoda/:id",
    component: AddUser,
    hidden: true //dùng để ẩn ở thanh sidebar

  },
  {
    name: "Danh Sách Thành Viên",
    layout: "/admin",
    path: "/families/:id",
    component: FamiliesDetail,
    hidden: true //dùng để ẩn ở thanh sidebar
  },
  {
    name: "Danh Sách Gia Đình",
    layout: "/admin",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    path: "/families",
    component: Families,
  },
  {
    name: "In Phiếu Cầu An",
    layout: "/admin",
    icon: <Icon as={FaFileWord} width='20px' height='20px' color='inherit' />,
    path: "/print-family/:id",
    component: PrintFamily,
    hidden: true //dùng để ẩn ở thanh sidebar
  },
];

// user
export const routesUser = [
  {
    name: "Danh Sách Thành Viên",
    layout: "/user",
    path: "/family/:id",
    component: FamilyDetail,
    hidden: true,
  },
  {
    name: "Danh Sách Gia Đình",
    layout: "/user",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    path: "/family",
    component: Family,
  },
  {
    name: "In Phiếu Cầu An",
    layout: "/user",
    icon: <Icon as={FaFileWord} width='20px' height='20px' color='inherit' />,
    path: "/print-family/:id",
    component: PrintFamily,
    hidden: true //dùng để ẩn ở thanh sidebar
  },

];


