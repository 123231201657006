import {
  Box,
  Button
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Card from "components/card/Card";
import { useForm } from "react-hook-form";


import {InputField} from "components/inputField/input";



const defaultValues = {
  email : '',
  name: '',
  nickName: '',
  birthDay: '',
  phoneNumber: '',
  password: '',
  role: 'staff'
};

export default function AddUser({
  handleCreateStaff,
  handleUpdateStaff,
  editStaff,
  isEditing,
  setIsEditing,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues,
  });
  

  useEffect(() => {
    if (isEditing && editStaff) {
      Object.keys(editStaff).forEach((key) => {
        if (key === "birthDay" && editStaff[key]) {
          // Chuyển đổi định dạng ngày sinh
          const date = new Date(editStaff[key]);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          setValue(key, formattedDate);
        } else {
          setValue(key, editStaff[key]);
        }
      });
    } else {
      reset(defaultValues);
    }
  }, [isEditing, editStaff, reset, setValue]);

  const onSubmit = async (formData) => {
    setLoading(true)
    const staffData = {
      ...formData,
      birthDay: formData.birthDay.split('/').reverse().join('-')
    };

    if (isEditing) {
      if (!staffData.password) {
        delete staffData.password;
      }
      await handleUpdateStaff(staffData);
    } else {
      await handleCreateStaff(staffData);
    }
    reset(defaultValues);
    setIsEditing(false);
    setShowPopup(false);
    setLoading(false)

  };

  const handleCancel = () => {
    setIsEditing(false);
    reset(defaultValues);
    setShowPopup(false);
  };

  const handleOverlayClick = (e) => {
    // Đóng khung nếu click bên ngoài ô đen
    if (e.target.classList.contains('overlay')) {
      setShowPopup(false);
    }
  };

  return (
    <div className="flex justify-between flex-wrap mt-10">

      {(showPopup || isEditing) ? (
        <>
          <Box className="overlay fixed inset-0 bg-black bg-opacity-50 z-40" onClick={handleOverlayClick}></Box>

          <Box className="popup fixed bottom-10 left-1/2 transform -translate-x-1/2 z-50 bg-white p-5 rounded-lg shadow-md overflow-y-scroll h-5/6 w-full min-w-xl transition-transform duration-300" 
            width={{
                  base: '90%',
                  xl: '60%'
            }}

          >
            <Card width='100%'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-12 mt-2">
                  <div className="border-b border-gray-900/10 pb-6">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      {isEditing ? 'Sửa Sư Quản Lí' : 'Thêm Sư Quản Lí'}
                    </h2>
                    <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-5">
                     
                      <InputField
                        label="Họ và tên:"
                        name="name"
                        register={register}
                        errors={errors}
                        required
                      />
                      <InputField
                        label="Pháp danh:"
                        name="nickName"
                        register={register}
                        errors={errors}
                        required
                      />
                      <InputField
                        label="Năm sinh:"
                        name="birthDay"
                        register={register}
                        errors={errors}
                        isEditing={isEditing}
                        required
                      />
                      <InputField
                        label="Số điện thoại:"
                        name="phoneNumber"
                        register={register}
                        errors={errors}
                        required
                      />
                       {!isEditing ? <InputField
                        label="Email:"
                        name="email"
                        register={register}
                        errors={errors}
                        required
                      /> : ''}
                      {!isEditing ? <InputField
                        label="Mật khẩu:"
                        name="password"
                        register={register}
                        errors={errors}
                        required
                      />: 
                        <InputField
                        label="Đổi mật khẩu mới (Không bắt buộc): "
                        name="password"
                        register={register}
                        errors={errors}
                        isEditing={isEditing}
                      />
                      }
                      </div>
                  </div>
                </div>
                <div className="mt-4 flex items-center justify-end gap-x-6">
                  <Button onClick={handleCancel} variant="ghost">
                    {isEditing ? 'Hủy cập nhật' : 'Hủy'}
                  </Button>
                  <Button
                    type='submit' 
                    bgColor="#4834d4" 
                    color='white' 
                    _hover={{color: '#4834d4', bgColor:'white', border: '0.5px solid #4834d4'}} 
                    borderRadius='5px'
                    isLoading={loading}
                  >
                    {isEditing ? 'Cập nhật' : 'Thêm'}
                  </Button>
                </div>
              </form>
            </Card>
          </Box>
        </>
      ) : (
        <div className="flex flex-col justify-end">
          <Button bgColor="#4834d4" color='white' _hover={{color: '#4834d4', bgColor:'white', border: '0.5px solid #4834d4'}} borderRadius='5px'
            onClick={() => setShowPopup(true)}>
            Thêm Sư Quản Lí
          </Button>
        </div>
      )}
    </div>
  );
}




