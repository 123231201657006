
import React, { createContext, useState, useContext, useEffect, useRef, useMemo } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    accessToken: null,
    role: null,
    pagodaId: null,
  });
  const [loading, setLoading] = useState(true);
  const initialLoad = useRef(true);

  useEffect(() => {
    if (initialLoad.current) {
      try {
        const accessToken = localStorage.getItem('@auth');
        const role = localStorage.getItem('role');
        const pagodaId = localStorage.getItem('pagodaId');

        if (accessToken && role) {
          setAuth({ accessToken, role, pagodaId });
        }
      } catch (error) {
        throw new error("Failed to load auth data from localStorage:", error);
      } finally {
        setLoading(false);
      }
      initialLoad.current = false;
    }
  }, []);

  const contextValue = useMemo(() => ({ auth, setAuth, loading }), [auth, loading]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

