import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter  } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import App from 'App';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { AuthProvider } from 'contexts/AuthContext';

ReactDOM.render(
	<ChakraProvider theme={theme}>
			<React.StrictMode>
				<ThemeEditorProvider>
						<BrowserRouter>
							<AuthProvider>
								<App/>
							</AuthProvider>
							<NotificationContainer />
						</BrowserRouter>
				</ThemeEditorProvider>
			</React.StrictMode>
		</ChakraProvider>,
	document.getElementById('root')
);
