import React, { useEffect, useMemo } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { Flex, Table, Thead, Tbody, Tr, Th, Td, Text, Box, Tooltip, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { format } from "date-fns";
import Pagination from "utils/customPagination";
import DragScroll from "react-indiana-drag-scroll";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import NoDataSvg from 'assets/icons/no-data.svg'

export default function Families({ columnsData, tableData, secondary, handleDelete, pagodas, handlePageChange, currentPage, totalPages, handleSearch }) {

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const history = useHistory();
  
  const handleViewDetails = (family) => {
    if (!family || !family._id) {
      return;
    }
    history.push(`/admin/families/${family._id}`, { name: 'Danh Sách Thành Viên' });
  };
  
  const formatDateTime = (dateTime) => {
    const vietnamTimeZone = 'Asia/Ho_Chi_Minh';
    return format(new Date(dateTime), 'dd/MM/yyyy HH:mm', { timeZone: vietnamTimeZone });
  };
  

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  
  const renderTableCell = (text) => {
    if (text === undefined || text === null) {
      text = ''; 
    } else if (typeof text !== 'string') {
      text = text.toString();
    }
    
    return (
      <Td
        fontSize={{ sm: "14px" }}
        minW={{ sm: "150px", md: "200px", lg: "auto" }}
        borderColor="transparent"
      >
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="400"
          >
            {text.charAt(0).toUpperCase() + text.slice(1)}
          </Text>
        </Flex>
      </Td>
    );
  };

  // hiển thị tên chùa
  const renderPagodaNameCell = (pagodaId) => {
    const pagoda = pagodas.find(p => p._id === pagodaId);
    return pagoda ? pagoda.name : 'Chùa đã bị xóa';
  };


  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: currentPage } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage
  } = tableInstance;

  useEffect(() => {
    gotoPage(currentPage);
  }, [currentPage, gotoPage]);

  return (
    <Card direction="column" w="100%" px="0px" overflowX="hidden">
     
        <Flex px="25px" justify="space-between" mb="20px" align="center" flexWrap="wrap">
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Danh Sách Gia Đình
          </Text>
          <SearchBar 
            searchFunction={handleSearch}
            w={{ base: "100%", md: "50%" }} 
            mt={{ base: "15px", md: "0px" }} 
            mb={secondary ? { base: "10px", md: "unset" } : "unset"} 
            me="10px" borderRadius="30px" 
          />
        </Flex> 
      <DragScroll>
        {data.length === 0 ? (
                 <Box px="25px" py="20vh" >
                 <Text color='#7f8c8d' fontSize="18px" fontWeight='500' className="flex justify-center flex-col items-center " >
                  <img src={NoDataSvg}  alt="No data icon"/>
                   <Box>Chưa Có Dữ Liệu</Box>
                 </Text>
               </Box>
          ) : (
          <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} pe="10px" key={index} borderColor={borderColor}>
                      <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400" className="text-nowrap"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                  <Th borderColor={borderColor} pe="10px">
                    <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }} width='200px' color="gray.400">
                      Chi tiết
                    </Flex>
                  </Th>
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                const { members, createdAt, pagodaId } = row.original;
                const actualRowIndex = rowIndex + 1 + currentPage * 8;
                return (
                  <Tr {...row.getRowProps()} key={rowIndex} className={rowIndex % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                    {members ? (
                      members.map((member, memberIndex) => {

                        return(
                          <React.Fragment key={member._id}>
                          {renderTableCell(actualRowIndex)}
                          {renderTableCell(renderPagodaNameCell(pagodaId))}
                          {renderTableCell(member.name)}
                          {renderTableCell(member.nickName)}
                          {renderTableCell(member.homeTown)}
                          {renderTableCell(member.address)}
                          {renderTableCell(formatDateTime(createdAt))}
                          <Td minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                            <Box className="flex justify-around items-center w-[150px] gap-2">
                              <Box onClick={() => {handleViewDetails(row.original)}}  className="cursor-pointer bg-cyan-600 p-2 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-cyan-600 border-2 hover:border-cyan-700">
                                <Tooltip label="Xem chi tiết gia đình">
                                  <Box fontSize="lg">
                                    Xem
                                  </Box>
                                </Tooltip>
                              </Box>
                              <Tooltip label="Xóa gia đình">
                                <Box onClick={() => { 
                                  handleDelete(row.original._id);
                                }} className="cursor-pointer bg-red-600 p-2 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-red-600 border-2 hover:border-red-700"  fontSize="lg">
                                  Xóa
                                </Box>
                              </Tooltip>
                            </Box>
                          </Td>
                        </React.Fragment>
                        )
                      })
                    ) : (
                      <>
                        {renderTableCell(actualRowIndex)}
                        {renderTableCell("")}
                        {renderTableCell("")}
                        {renderTableCell("")}
                        {renderTableCell("")}
                        {renderTableCell("")}
                        {renderTableCell("")}
                        {renderTableCell("")}
              
                      </>
                    )
                    }
                  
                  </Tr>
                );
              })}
            </Tbody>    

          </Table>
        )}
      </DragScroll>
      {data.length > 0 && (
        <Pagination
          pageCount={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
</Card>
);
}
