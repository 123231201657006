import { Text, Textarea } from "@chakra-ui/react";
import { format, isValid, parse } from "date-fns";
import { useState } from "react";
import { MAX_LENGTH, INPUT_CANNOT_EMPTY, INVALID_EMAIL, INVALID_PASSWORD, INVALID_PHONE_NUMBER, ERROR_BIRTHDAY_STAFF, ERROR_BIRTHDAY } from "utils/constants";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { emailRegex, phoneRegex } from "utils/regex";

export const InputField = ({
  label,
  name, 
  register, 
  errors, 
  onInput, 
  required, 
  defaultValue, 
  isEditing
}) => {
  const [charCount, setCharCount] = useState(0);

  // Giới hạn kí tự
  const maxLength = (name === "description") ? 255 : 100;

  const isValidYear = (value) => /^[0-9]{4}$/.test(value) && parseInt(value, 10) >= 1000;

  const isValidDateVN = (dateString) => {
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
    return isValid(parsedDate) && dateString === format(parsedDate, 'dd/MM/yyyy');
  };

  const isValidEmail = (email) => {
    const lowerCaseEmail = email.toLowerCase();
    return emailRegex.test(lowerCaseEmail);
  };
  

  const isValidPassword = (password) => password.length >= 8;
  const isValidPhoneNumber = (phoneNumber) => phoneRegex.test(phoneNumber);

  const handleInput = (e) => {
    if (name === "phoneNumber") {
      e.target.value = formatPhoneNumber(e.target.value);
    }
    if (name === "email") {
      e.target.value = e.target.value.toLowerCase();
    }
    setCharCount(e.target.value.length);
    if (onInput) {
      onInput(e);
    }
  };


  const validateInput = (value) => {
    if(!isEditing){
      // Tránh việc bỏ trống ô input
      if (value?.trim() === "") {
        return INPUT_CANNOT_EMPTY;
      }
    }
    
    if (name === "birthDay" && !isValidDateVN(value)) {
      return ERROR_BIRTHDAY_STAFF; 
    }
    if(!isEditing){
      if (name === "birthday" && !isValidYear(value)) {
        return ERROR_BIRTHDAY;
      }
    }
    if (name === "email" && !isValidEmail(value)) {
      return INVALID_EMAIL;
    }
    if(!isEditing){
      if (name === "password" && !isValidPassword(value)) {
        return INVALID_PASSWORD;
      }
    }
    if (name === "phoneNumber" && !isValidPhoneNumber(value)) {
      return INVALID_PHONE_NUMBER;
    }
    return true;
  };


  return (
    <div className="sm:col-span-2">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="mt-2">
        {
         name === "description" ? 
         <Textarea
            {...register( name, {
              required: INPUT_CANNOT_EMPTY,
            })}
            id={name}
            onInput={handleInput}
            maxLength={maxLength}
            resize='none'
            p='7px'
            className="w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
          /> :
          <input
          {...register( name, {
              required: !isEditing ? INPUT_CANNOT_EMPTY : false,
              validate: validateInput
            })}
            type={name === "password" ? "password" : "text"}
            id={name}
            onInput={handleInput}
            className="block p-2 w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
            maxLength={maxLength}
            defaultValue={defaultValue}
          />
        }
        {charCount >= maxLength && (
          <Text color="red.500">{MAX_LENGTH}</Text>
        )}
         {errors[name] && <Text color="red.500">{errors[name].message}</Text>} 
      </div>
    </div>
  );
};



