
export const dataMember = [
  {
    Header: 'STT',
    accessor: (_, index) => index + 1,
  },
  {
    Header: "Chủ bái",
    accessor: "name",
  },
  {
    Header: "Pháp danh",
    accessor: "nickName",
  },
  {
    Header: "Nguyên quán",
    accessor: "homeTown",
  },
  {
    Header: "Hiện trú",
    accessor: "address",
  },
  {
    Header: "Ngày tạo",
    accessor: "createdAt",
  }
];

