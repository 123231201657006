import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import FamilyDetail from "./components/FamilyDetail";
import ListMember from "./components/ListMember";
import { dataMember } from "./variables/columnsData";
import Swal from "sweetalert2";
import {  NotificationManager } from 'react-notifications';
import { handleGetFamilyAndMembers, handleGetYearBirthday } from "apis/user";
import { ERROR_SERVER, ERROR_CREATED, ERROR_DELETED, CREATED_SUCCESSFULLY, 
  DELETED_SUCCESSFULLY, UPDATED_SUCCESSFULLY, CONFIRM_DELETED_TITLE, CONFIRM_DELETED_TEXT,
  ERROR_UPDATED_OWNER, UPDATED_OWNER_SUCCESSFULLY 
} from "utils/constants";
import { useAuth } from "contexts/AuthContext";
import { handleCreateMember, handleUpdateMember, handleDeleteMember, handleUpdateOwner } from "apis/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom";


const Settings = () => {
  const location = useLocation();
  const { auth } = useAuth();
  const { pagodaId } = auth;

  const { id } = useParams();
  const { family: familyState } = location.state || {};
  const history = useHistory()
  const [family, setFamily] = useState(familyState || null);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMember, setEditMember] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [birthdayZodiac, setBirthdayZodiac] = useState([])

  const handleGetFamilyAndMembersData = async () => {
    try {
        setLoading(true);
        const { family, members } = await handleGetFamilyAndMembers(id, pagodaId);
        const zodiac = await handleGetYearBirthday(family._id)

        if (!family || !members) {
          history.push('/not-found');
          return;
        }
        // Cập nhật birthday từ zodiac
        const updatedMembers = members.map(member => {
          const zodiacMember = zodiac.find(z => z._id === member._id);
          return zodiacMember ? { ...member, birthdayAndZodiac: zodiacMember.birthdayAndZodiac } : member;
        });

        setFamily(family);
        setMembers(updatedMembers);
        setBirthdayZodiac(zodiac)
        setLoading(false);
    } catch (error) {
        setLoading(false);
        NotificationManager.error(ERROR_SERVER, error.message);
    }
  };




  useEffect(() => {
    handleGetFamilyAndMembersData();
  }, [shouldUpdate]);

  //tạo thành viên
  const handleCreate = async (newMember) => {
    try {
      const member  = await handleCreateMember(newMember, family._id, pagodaId);
      setMembers([...members, member]);
      setShouldUpdate(true)

      NotificationManager.success(CREATED_SUCCESSFULLY);
    } catch (error) {
      setShouldUpdate(true);
      NotificationManager.error(ERROR_CREATED, error.message);
    }
  };

  // Cập nhật thành viên
  const handleUpdate = async (updatedMember) => {
    try {
      // Tạo một đối tượng dữ liệu cập nhật với chỉ trường `birthday`
      const dataToUpdate = {
        ...updatedMember,
        // Xóa `birthdayAndZodiac` nếu tồn tại
        birthdayAndZodiac: undefined,
      };

      const updatedMemberData = await handleUpdateMember(dataToUpdate, family._id, family);

      // Cập nhật danh sách thành viên
      setMembers(prevMembers =>
        prevMembers.map(member =>
          member._id === updatedMember._id ? updatedMemberData : member
        )
      );

      setShouldUpdate(true);
      setIsEditing(false);
      setEditMember(null);
      NotificationManager.success(UPDATED_SUCCESSFULLY);

    } catch (error) {
      setShouldUpdate(true);
      NotificationManager.error(error.message);
    }
  };

  

  //xóa thành viên
  const handleDelete = async (memberId) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: CONFIRM_DELETED_TITLE,
        text: CONFIRM_DELETED_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy'
      });

      if (isConfirmed) {
        const  updatedFamily  = await handleDeleteMember(memberId, family);

        // const updatedMembers = members.filter(member => member._id !== memberId);

        setMembers(updatedFamily);
        setFamily(updatedFamily);

        NotificationManager.success(DELETED_SUCCESSFULLY);
        setShouldUpdate(true);

      }
    } catch (error) {
      NotificationManager.error(ERROR_DELETED, error);
    }
  };

  const handleEditMember = (member) => {
    setEditMember(member);
    setIsEditing(true);
  };

  // đổi thành viên thành chủ bái
  const handleSetAsOwner = async (newOwnerId, memberIdToSwitch) => {
    try {
      
      const { family, members } = await handleGetFamilyAndMembers(id, pagodaId);
      if (!family || !family.onwerId) {
        throw new Error("Family or ownerId is not defined");
      }
    
      const currentOwner = members.find(member => member._id === family.onwerId);

      const currentOwnerId = currentOwner._id;
    
      const updated = await handleUpdateOwner(newOwnerId, currentOwnerId, memberIdToSwitch, family._id, pagodaId);
      if (updated) {
      
        setFamily({ ...family, onwerId: newOwnerId });
        setShouldUpdate(true)
        NotificationManager.success(UPDATED_OWNER_SUCCESSFULLY);
        
      }
   
    } catch (error) {
      NotificationManager.error(ERROR_UPDATED_OWNER, error);
    }
  };

  useEffect(() => {
    if (shouldUpdate) {
      handleGetFamilyAndMembersData();
      setShouldUpdate(false); 
    }
  }, [shouldUpdate]);

  useEffect(() => {
    if (family && Array.isArray(family.membersId) && Array.isArray(members)) {
      const filteredMembers = members.filter(member => family.membersId.includes(member._id) || member._id === family.onwerId);
      setFamilyMembers(filteredMembers);
    } else {
      setFamilyMembers([]);
    }
  }, [family, members]);
  
  // đem chủ bái lên đầu
  const sortedFamilyMembers = familyMembers.sort((a, b) => {
    if (a._id === family.onwerId) return -1;
    if (b._id === family.onwerId) return 1;
    
    return 0;
  });

  if (loading) {
    return (
      <Box className="relative">
        <Box className="absolute flex justify-center items-center gap-2 top-80 left-[45%]">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='lg'
          /> <Text>Đang tải dữ liệu...</Text> 
        </Box>
      </Box>
    );
  }

  const getOwnerInfo = sortedFamilyMembers?.filter((member) => member?._id === family?.onwerId);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mb='20px' columns='1' spacing={{ base: "20px", xl: "20px" }}>
      <FamilyDetail 
            handleCreate={handleCreate} 
            editMember={editMember} 
            isEditing={isEditing} 
            setIsEditing={setIsEditing} 
            handleUpdate={handleUpdate} 
            family={family}
            ownerInformation={getOwnerInfo}
          />
        <ListMember
          columnsData={dataMember}
          tableData={sortedFamilyMembers}
          handleEditMember={handleEditMember}
          handleDelete={handleDelete}
          handleSetAsOwner={handleSetAsOwner}
          currentOwnerId={family?.onwerId}
          familyId={family?._id}
          pagodaId={pagodaId}
          birthdayZodiac={birthdayZodiac}
        />
      </SimpleGrid>
    </Box>
  );
};

export default Settings;
