import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Flex, Text, Tooltip, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import Card from "components/card/Card";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Pagination from "utils/customPagination";
import DragScroll from "react-indiana-drag-scroll";
import NoDataSvg from 'assets/icons/no-data.svg'

export default function ListUser({ tableData, columnsData, secondary, handleEditPagoda, handleDeleteStaff }) {

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(8);

  // chuyển trang
  const handlePageChange = useCallback(({ selected }) => {
    setCurrentPage(selected);
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize, pageIndex: currentPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    pageCount
  } = tableInstance;

  useEffect(() => {
    gotoPage(currentPage);
  }, [currentPage, gotoPage]);

  return (
    <Card direction="column" w="100%" px="0px" overflowX="scroll"
      css={{
        "&::-webkit-scrollbar": {
          height: '10px',
          width: '10px'
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#c8d6e5",
          borderRadius: "5px",
        }
      }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center" flexWrap="wrap">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Danh Sách Thông Tin Sư Quản Lí
        </Text>
      </Flex>
      <DragScroll>
        {data.length === 0 ? (
          <Box px="25px" py="20vh" >
            <Text color='#7f8c8d' fontSize="18px" fontWeight='500' className="flex justify-center flex-col items-center " >
              <Box><img src={NoDataSvg}  alt="No data icon"/></Box>
              <Box>Chưa Có Dữ Liệu</Box>
            </Text>
          </Box>
        ) : (
          <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} pe="10px" key={index} borderColor={borderColor}>
                      <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400" className="text-nowrap">
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                  <Th borderColor={borderColor} pe="10px">
                    <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                      Chi tiết
                    </Flex>
                  </Th>
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                    {row.cells.map((cell, index) => (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        <Flex align="center">
                          <Text color={textColor} fontSize="sm" fontWeight="400" className="text-nowrap">
                            {cell.value && typeof cell.value === 'string' ? 
                              cell.value.charAt(0).toUpperCase() + cell.value.slice(1) 
                              : 
                              cell.value
                            }
                          </Text>
                        </Flex>
                      </Td>
                    ))}
                    <Td minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                      <Box className="flex justify-start items-center gap-2">
                        <Tooltip label="Chỉnh sửa Sư Quản Lí">
                          <Box cursor="pointer" onClick={() => handleEditPagoda(row.original)} 
                            className="cursor-pointer bg-cyan-600 p-2.5 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-cyan-600 border-2 hover:border-cyan-700"  fontSize="lg">
                            <FaEdit/>
                          </Box>
                        </Tooltip>
                        <Tooltip label="Xóa Sư Quản Lí">
                          <Box onClick={() => handleDeleteStaff(row.original._id)} 
                            className="cursor-pointer bg-red-600 p-2 rounded-md px-5 text-gray-50 hover:bg-gray-50 hover:text-red-600 border-2 hover:border-red-700"  fontSize="lg">
                            Xóa
                          </Box>
                        </Tooltip>
                      </Box>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
      </DragScroll>
      {data.length > 0 && (
        <Pagination
          pageCount={pageCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </Card>
  );
}
