
// Chakra imports
import { Box, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";


import { dataUser } from "./variables/columnsData";
import AddUser from "./components/AddUser";
import ListUser from "./components/ListUser";
import { 
  ERROR_SERVER, 
  ERROR_CREATED, 
  ERROR_DELETED, 
  CREATED_SUCCESSFULLY, 
  DELETED_SUCCESSFULLY, 
  UPDATED_SUCCESSFULLY, 
  ERROR_UPDATED, 
  CONFIRM_DELETED_TITLE, 
  CONFIRM_DELETED_TEXT 
} from "utils/constants";
import { NotificationManager } from "react-notifications";
import { handleGetStaffsOfPagoda } from "apis/admin";
import {  useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { handleCreateStaffs } from "apis/admin";
import { handleUpdateStaffs } from "apis/admin";
import Swal from "sweetalert2";
import { handleDeleteStaffs } from "apis/admin";
import { ERROR_DELETED_MEMBER } from "utils/constants";


export default function Settings() {

  const { id } = useParams();

  const [staffs, setStaffs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editStaff, setEditStaff] = useState(null);
  const history = useHistory()

  const [isEditing, setIsEditing] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    handleGetStaff();
  }, [shouldUpdate]);

  useEffect(() => {
    if (shouldUpdate) {
      handleGetStaff();
      setShouldUpdate(false); 
    }
  }, [shouldUpdate]);

  //lấy dữ liệu
    const handleGetStaff = async () => {
      try {
        setLoading(true);
        const staffData  = await handleGetStaffsOfPagoda(id);

        if (!staffData) {
          history.push('/not-found');
          return;
        }
        
        setStaffs(staffData); 
        setLoading(false);
  
      } catch (error) {
  
        NotificationManager.error(ERROR_SERVER);
  
        setLoading(false);
  
      }
    };

    // tạo user
  const handleCreateStaff = async ( staffData) => {
    try {
      
      const newStaff = await handleCreateStaffs(id, staffData)

      setStaffs((prevStaff) => [...prevStaff, newStaff]);
      setShouldUpdate(true)
      NotificationManager.success(CREATED_SUCCESSFULLY);

    } catch (error) {
      const errorMessage = error.message || 'Đã xảy ra lỗi';
      NotificationManager.error(errorMessage);
      setShouldUpdate(true);
    }
  }

  // cập nhật user
  const handleUpdateStaff = async (updateStaff) => {
    try {
      const updated = await handleUpdateStaffs(updateStaff);
    
        setStaffs(prevStaffs => {
          return prevStaffs.map(staff =>
              staff._id === updated._id ? updated : staff
          );
        });
        setShouldUpdate(true)
        setIsEditing(false);
        setEditStaff(null);
        NotificationManager.success(UPDATED_SUCCESSFULLY);
    
    } catch (error) {
      NotificationManager.error(error.message);
      setShouldUpdate(true);
    }
  };

  //delete user
  const handleDeleteStaff = async (staffData) => {
    try {
      
      const { isConfirmed } = await Swal.fire({
        title: CONFIRM_DELETED_TITLE,
        text: CONFIRM_DELETED_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy'
      });
  
      if (isConfirmed) {
        await handleDeleteStaffs(staffData)
        setStaffs(prevStaffs => prevStaffs.filter(staff => staff._id !== staffData));
        NotificationManager.success(DELETED_SUCCESSFULLY);
        setShouldUpdate(true);
      }
    } catch (error) {
      NotificationManager.error(ERROR_DELETED);
    }
  }


  if (loading) {
    return (
      <Box className="relative">
        <Box className="absolute flex justify-center items-center gap-2 top-80 left-[45%]">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='lg'
          /> <Text>Đang tải dữ liệu...</Text> 
        </Box>
      </Box>
    );
  }

  // show edit
  const handleEditPagoda = (pagoda) => {
    setEditStaff(pagoda);
    setIsEditing(true);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
         mb='20px' columns='1' spacing={{ base: "20px", xl: "20px" }}>

        <AddUser
          handleCreateStaff={handleCreateStaff}
          handleUpdateStaff={handleUpdateStaff}
          editStaff={editStaff} 
          isEditing={isEditing} 
          setIsEditing={setIsEditing}
        />

        <ListUser
          columnsData={dataUser} 
          tableData={staffs}
          handleEditPagoda={handleEditPagoda}
          handleDeleteStaff={handleDeleteStaff}
        />
      </SimpleGrid>
    </Box>
  );
}
