import { format } from "date-fns";

const formatDateTime = (dateTime) => {
  if (!dateTime) {
    return ''; 
  }
  const vietnamTimeZone = 'Asia/Ho_Chi_Minh';
  return format(new Date(dateTime), 'dd/MM/yyyy HH:mm', { timeZone: vietnamTimeZone });
};

const formatBirthday = (birthday) => {
  if (!birthday) {
    return ''; 
  }
  return format(new Date(birthday), 'dd/MM/yyyy');
};

export const dataUser = [
  {
    Header: 'STT',
    accessor: (_, index) => index + 1,
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Họ và tên",
    accessor: "name",
  },
  {
    Header: "Pháp danh",
    accessor: "nickName",
  },
  {
    Header: "Năm sinh",
    accessor: (row) => formatBirthday(row.birthDay),
  },
  {
    Header: "Số điện thoại",
    accessor: "phoneNumber",
  },
  {
    Header: "Ngày tạo",
    accessor: (row) => formatDateTime(row.createdAt),
  }
];

