import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import DefaultAuth from "layouts/auth/Default";
import validator from "validator";
import { forgot } from "apis/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { FORGOT_PASSWORD_REQUIRED } from "utils/constants";
import { FORGOT_PASSWORD_INVALID } from "utils/constants";
import { GrLinkPrevious } from "react-icons/gr";
import { forgotPassword } from "apis/auth";
import { PASSWORD_MINLENGTH_INVALID } from "utils/constants";
import { PASSWORD_MAXLENGTH_INVALID } from "utils/constants";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { PASSWORD_REQUIRED } from "utils/constants";
import { NotificationManager } from "react-notifications";
import { SEND_EMAIL_SUCCESSFULLY } from "utils/constants";
import { FORGOT_PASSWORD_SUCCESSFULLY } from "utils/constants";
import { FORGOT_PASSWORD_ERROR } from "utils/constants";

function ForgotPassword() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [forgotId, setForgotId] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // xác định hiển thị form nhập lại mật khẩu
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false); 
  
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const { email } = data;
      let trimUsername = email.trim().toLowerCase()
      const response = await forgot(trimUsername);

      setForgotId(response);

      NotificationManager.success(SEND_EMAIL_SUCCESSFULLY);

      // Đã gửi email thành công, hiển thị form nhập lại mật khẩu
      setShowResetPasswordForm(true);

    } catch (error) {
      setError(error.message || FORGOT_PASSWORD_ERROR);
    } finally {
      setLoading(false);
    }
  };

  const onResetPasswordSubmit = async (data) => {
    setLoading(true);
    try {

      const payload = { password: data.password };
      
      await forgotPassword(forgotId, payload);

      NotificationManager.success(FORGOT_PASSWORD_SUCCESSFULLY);

      history.push('/login');

    } catch (error) {
      setError(error.message || FORGOT_PASSWORD_ERROR);
    }
    setLoading(false);
  };

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
      >
        <Box
          className=" border-2 p-10"
          borderRadius='15px'
        >
          <Box>
            <Heading color={textColor} fontSize='25px' mb='40px' textAlign='center'>
              {!showResetPasswordForm ? 
                (error ? <Box color='red' fontSize='20px' mb='45px'>{error}</Box> : 'Khôi Phục Mật Khẩu' )
                :
                'Nhập Mật Khẩu Mới' 
              }
            </Heading>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}
          >
            {!showResetPasswordForm ? ( // Nếu chưa hiển thị form nhập lại mật khẩu
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'
                  >
                    Nhập lại Email<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    variant='auth'
                    fontSize='sm'
                    type='email'
                    placeholder='mail@gmail.com'
                    mb='5px'
                    fontWeight='500'
                    size='lg'
                    {...register('email', {
                      required: FORGOT_PASSWORD_REQUIRED,
                      validate: (value) => validator.isEmail(value.trim()) || FORGOT_PASSWORD_INVALID,
                    })}
                  />
                  {errors.email && <Text color="red.500">{errors.email.message}</Text>}
                  <Button
                    type="submit"
                    fontSize='lg'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    isLoading={loading}
                    mb='10px'
                    mt='30px'
                  >
                    Gửi
                  </Button>
                </FormControl>
              </form>
            ) : ( // Nếu hiển thị form nhập lại mật khẩu
              <form onSubmit={handleSubmit(onResetPasswordSubmit)}>
                <FormControl>
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'
                  >
                    Mật khẩu<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size='md'>
                  <Input
                    fontSize='sm'
                    placeholder='Nhập đầy đủ kí tự mật khẩu'
                    mb='5px'
                    size='lg'
                    type={show ? "text" : "password"}
                    variant='auth'
                    {...register('password', {
                      required: PASSWORD_REQUIRED,
                      minLength: {
                        value: 8,
                        message: PASSWORD_MINLENGTH_INVALID
                      },
                      maxLength: {
                        value: 30,
                        message: PASSWORD_MAXLENGTH_INVALID
                      }
                    })}
                  />
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                  </InputRightElement>
                </InputGroup>
                  {errors.password && <Text color="red.500">{errors.password.message}</Text>}
                  <Button
                    type="submit"
                    fontSize='lg'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    isLoading={loading}
                    mb='10px'
                    mt='30px'
                  >
                    Đổi Mật Khẩu
                  </Button>
                </FormControl>
              </form>
            )}
            <Flex justifyContent='space-between' align='center' mt='24px'>
              <Text color={textColorSecondary} fontSize='sm'>
                <NavLink to='/login'>
                  <Text color={textColorBrand} fontWeight='500' className="flex items-center" >
                    <GrLinkPrevious className="mr-1" />
                    <span>Trở lại đăng nhập</span>
                  </Text>
                </NavLink>
              </Text>
              {/* <Text color={textColorSecondary} fontSize='sm'>
                <Tooltip label="Nhập đúng email để đổi password">
                  <Text fontWeight='500'>Trợ giúp!</Text>
                </Tooltip>
              </Text> */}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default ForgotPassword;
