import {  getTakenData } from "utils/api";
import { ERROR_SERVER } from "utils/constants";

export const handleGetFamilesAndMemberPrint = async (familyId) => {
  try {

    const [familyRes, membersZodiac] = await Promise.all([
      getTakenData(`/api/v1/family/${familyId}`),
      getTakenData(`/api/v1/family/members-zodiac/${familyId}`),
    ]);
  
    const familyData = familyRes?.data;
    const membersZodiacs = membersZodiac?.data?.members

    return { family: familyData, zodiac: membersZodiacs };
  } catch (error) {
    console.error(ERROR_SERVER, error);
  }
};