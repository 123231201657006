import React, {useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Flex, Text, Tooltip, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import Card from "components/card/Card";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Pagination from "utils/customPagination";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import DragScroll from "react-indiana-drag-scroll";

const MemberButton = ({ member, currentOwnerId, handleSetAsOwner  }) => {

  // đổi thành viên thành chủ bái
  const handleClick = async () => {
    if (member._id === currentOwnerId) {
      await handleSetAsOwner(null, member._id);
    } else {
      await handleSetAsOwner(member._id, null);
    }
  };
  const buttonColor = useColorModeValue("white", "gray.800");
  const borderColor = member._id === currentOwnerId ? "#b71540" : "#33d9b2";
  const textColor = member._id === currentOwnerId ? "#b71540" : "#33d9b2";
  const padding = member._id === currentOwnerId ? "0 28px" : "";

  return (
    <Button
      onClick={handleClick}
      fontSize="md"
      backgroundColor={buttonColor}
      color={textColor}
      borderColor={borderColor}
      borderWidth="2px"
      padding={padding}
      borderRadius="5px"
      disabled={member._id === currentOwnerId}
      className={member._id === currentOwnerId ? "move-up" : ""}
      _hover={{
        backgroundColor: buttonColor,
        color: textColor,
        borderColor: borderColor,
      }}
      _disabled={{
        opacity: 0.7,
        cursor: "not-allowed",
      }}
    >
      <Tooltip label={member._id === currentOwnerId ? "" : "Nhấn để thay đổi thành Chủ bái"}>
        {member._id === currentOwnerId ? "Chủ bái" : "Thành viên"}
      </Tooltip>
    </Button>
  );
};


export default function ListMember({ tableData, columnsData, secondary, currentOwnerId, handleEditMember, handleDelete, handleSetAsOwner, familyId, birthdayZodiac }) {

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const history = useHistory();

  // const columns = useMemo(() => columnsData, [columnsData]);
  const columns = useMemo(() => {
    // Cập nhật columnsData để sử dụng birthdayAndZodiac
    const updatedColumns = columnsData.map(col => {
      if (col.accessor === 'birthday') {
        return { ...col, accessor: 'birthdayAndZodiac' };
      }
      return col;
    });
    return updatedColumns;
  }, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(8);

  //chuyển trang in phiếu cầu an
  const handleNavigateToPrintPage = () => {
    history.push(`/admin/print-family/${familyId}`, { name: 'In Phiếu Cầu An' });
  };

  const handlePageChange = useCallback(({ selected }) => {
    setCurrentPage(selected);
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize  , pageIndex: currentPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage
  } = tableInstance;

  useEffect(() => {
    gotoPage(currentPage);
  }, [currentPage, gotoPage]);

  return (
    <Card direction="column" w="100%" px="0px" overflowX="hidden">
        <Flex px="25px" justify="space-between" mb="20px" align="center" flexWrap="wrap">
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Danh Sách Thành Viên
          </Text>
          <Box onClick={handleNavigateToPrintPage} className="cursor-pointer bg-cyan-600 p-2 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-cyan-600 border-2 hover:border-cyan-700"  fontSize="lg">
            In phiếu cầu an
          </Box>
        </Flex>
        <DragScroll>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())} pe="10px" key={index} borderColor={borderColor}>
                    <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400" className="text-nowrap"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
                <Th borderColor={borderColor} pe="10px">
                  <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                    Chủ bái / <br/>
                    Thành viên
                  </Flex>
                </Th>
                <Th borderColor={borderColor} pe="10px">
                  <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }} width='150px' color="gray.400">
                    Chi tiết
                  </Flex>
                </Th>
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white" } >
                 {row.cells.map((cell, index) => (
                    <Td
                      px='10px'
                      pl='23px'
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      borderColor="transparent"
                      backgroundColor={row.original._id === currentOwnerId ? "blackAlpha.50" : "transparent"}
                    >
                    <Flex align="center">
                      <Text color={textColor} fontSize="sm" fontWeight="400" 
                      >
                        {cell.value && typeof cell.value === 'string' ? 
                          cell.value.charAt(0).toUpperCase() + cell.value.slice(1) 
                          : 
                          cell.value
                        }
                      </Text>
                    </Flex>
                  </Td>
                  ))}
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent" 
                    backgroundColor={row.original._id === currentOwnerId ? "blackAlpha.50" : "transparent"}
                  >
                    <Box>
                      <MemberButton
                        member={row.original}
                        currentOwnerId={currentOwnerId}
                        handleSetAsOwner={handleSetAsOwner}
                      />
                    </Box>
                  </Td>
                  { row.original._id === currentOwnerId ?
                    <Td minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent" backgroundColor="blackAlpha.50">
                      <Box className="flex justify-start items-center gap-1">
                        <Tooltip label="Chỉnh sửa chi tiết gia đình">
                          <Box cursor="pointer" onClick={() => handleEditMember(row.original)} 
                            className="cursor-pointer bg-cyan-600 p-2.5 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-cyan-600 border-2 hover:border-cyan-700"  fontSize="lg">
                            <FaEdit/>
                          </Box>
                        </Tooltip>
                      </Box>
                    </Td>
                    :
                    <Td minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                    <Box className="flex justify-start items-center gap-1">
                      <Tooltip label="Chỉnh sửa chi tiết gia đình">
                        <Box cursor="pointer" onClick={() => handleEditMember(row.original)} 
                          className="cursor-pointer bg-cyan-600 p-2.5 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-cyan-600 border-2 hover:border-cyan-700"  fontSize="lg">
                          <FaEdit/>
                        </Box>
                      </Tooltip>
                      <Tooltip label="Xóa gia đình">
                        <Box 
                          onClick={() => handleDelete(row.original._id)} 
                          className="cursor-pointer bg-red-600 p-2 rounded-md px-5 text-gray-50 hover:bg-gray-50 hover:text-red-600 border-2 hover:border-red-700"  fontSize="lg">
                          Xóa
                        </Box>
                      </Tooltip>
                    </Box>
                  </Td>
                  }
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        </DragScroll>

        <Pagination
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </Card>
  );
}
