import React, { useState } from 'react';
import Card from 'components/card/Card';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@chakra-ui/react';
import { InputField } from 'components/inputField/input';
import { GenderField } from 'components/genderField/gender';


const defaultValues = {
  name: '',
  nickName: '',
  address: '',
  birthday: '',
  gender: '',
  homeTown: '',
};

const AddMember = ({ handleCreate }) => {
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues,
  });

  const onSubmit = (formData) => {
    setLoading(true)
    const familyData = {
      ...formData,
      // birthday: formData.birthday.split('/').reverse().join('-'),
    };

    handleCreate(familyData);
    reset(defaultValues);
    setShowPopup(false);
    setLoading(false)
  };

  const handleCancel = () => {
    reset(defaultValues);
    setShowPopup(false);
  };

  const handleOverlayClick = (e) => {
    // Đóng khung nếu click bên ngoài ô đen
    if (e.target.classList.contains('overlay')) {
      setShowPopup(false);
    }
  };

  return (
    <div className='flex  justify-between mb-3 mt-1 relative'>
      <div>
        <Button bgColor="#4834d4" color='white' _hover={{color: '#4834d4', bgColor:'white', border: '0.5px solid #4834d4'}} borderRadius='5px'
            onClick={() => setShowPopup(true)}>
            Thêm gia đình
        </Button>
      </div>
      {showPopup && (
        <>
          <Box className="overlay fixed inset-0 bg-black bg-opacity-50 z-40" onClick={handleOverlayClick}></Box>

          <Box className="popup fixed bottom-10 left-1/2 transform -translate-x-1/2 z-50 bg-white p-5 rounded-lg shadow-md overflow-y-scroll h-5/6 w-full min-w-xl transition-transform duration-300" 
            width={{
                  base: '90%',
                  xl: '60%'
            }}
          >
            <Card
              width='100%'
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-12 mt-2">
                  <div className="border-b border-gray-900/10 pb-6">
                    <Box className="flex w-full justify-between">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Thêm gia đình
                      </h2>
                    </Box>
                    <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-5">
                      <InputField
                        label="Tên chủ bái:"
                        name="name"
                        register={register}
                        errors={errors}
                        required
                      />
                      <GenderField
                        register={register}
                        errors={errors}
                        required
                      />
                      <InputField
                        label="Pháp danh:"
                        name="nickName"
                        register={register}
                        errors={errors}
                        required
                      />
                      <InputField
                        label="Năm sinh:"
                        name="birthday"
                        register={register}
                        errors={errors}
                        required
                      />
                       <InputField
                        label="Nguyên quán:"
                        name="homeTown"
                        register={register}
                        errors={errors}
                        required
                      />
                      <InputField
                        label="Hiện trú:"
                        name="address"
                        register={register}
                        errors={errors}
                        required
                      />

                    </div>
                  </div>
                </div>
                <div className="mt-4 flex items-center justify-end gap-x-6" > 
                  <Button onClick={handleCancel} variant="ghost">
                    Hủy
                  </Button>
                  <Button 
                    type='submit' 
                    bgColor="#4834d4" 
                    color='white' 
                    _hover={{color: '#4834d4', bgColor:'white', border: '0.5px solid #4834d4'}} 
                    borderRadius='5px'
                    isLoading={loading}
                  >
                    Thêm
                  </Button>
                </div>
              </form>
            </Card>
          </Box>
        </>
      )}
    </div>
  );
};

export default AddMember;


