import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Spinner, Text } from "@chakra-ui/react";

import {

  dataMember,
} from "views/admin/families/variables/columnsData";

import AddMember from "./components/AddMember";
import Families from "./components/Families";
import Swal from "sweetalert2";
import {  NotificationManager } from 'react-notifications';
import { ERROR_SERVER, ERROR_CREATED, ERROR_DELETED, CREATED_SUCCESSFULLY, DELETED_SUCCESSFULLY } from "utils/constants";
import { CONFIRM_DELETED_TEXT } from "utils/constants";
import { CONFIRM_DELETED_TITLE } from "utils/constants";
import { handleGetFamilesAdmin } from "apis/admin";
import { handleCreateFamilyAdmin } from "apis/admin";
import { handleDeleteFamilyAdmin } from "apis/admin";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { handleChoosePagodas } from "apis/admin";
import { useDebounce } from "hooks/useDebounce";

export default function Settings() {

  const [members, setMembers] = useState([]);  
  const [families, setFamilies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [pagodas, setPagodas] = useState([]);
  const [shouldUpdateFamilies, setShouldUpdateFamilies] = useState(true); 
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  // get dữ liệu
  useEffect(() => {
    const handleGetAllFamily = async () => {
      try {
        setLoading(true);
        const limit = 8;
        const { familyData, membersData, totalPages } = await handleGetFamilesAdmin(page + 1, limit, debouncedSearchQuery);

        if (!familyData || !membersData) {
          history.push('/not-found');
          return;
        }
        const combinedData = familyData.map(family => {
          const ownerIds = family.onwerId || []; 
          const familyMembers = membersData.filter(member => member._id === ownerIds);
          return { ...family, members: familyMembers };
        });

        setFamilies(combinedData); 
        setTotalPages(totalPages); 

        setLoading(false);
        setShouldUpdateFamilies(false);

        history.push(`/admin/families?page=${page + 1}`);
        
      } catch (error) {
        NotificationManager.error(ERROR_SERVER, error.message);
        setLoading(false);
      }
    };

    handleGetAllFamily();
  }, [ shouldUpdateFamilies, history, page, debouncedSearchQuery]);

  // lấy dữ liệu pagoda để lọc danh sách
  useEffect(() => {
    const loadPagodas = async () => {
      try {
        const pagodaList = await handleChoosePagodas();
        setPagodas(pagodaList);
      } catch (error) {
        throw new error('Error fetching pagodas:', error);
      }
    };

    loadPagodas();
  }, []);

  // chuyển trang
  const handlePageChange = (newPage) => {
    setPage(newPage.selected);
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    setSearchQuery(searchQuery);
    setPage(0);
    setShouldUpdateFamilies(false);
  }

  // tạo thành viên
  const handleCreate = async (newMember, selectedPagoda) => {
    try {
      
      const { family, member } = await handleCreateFamilyAdmin(newMember, selectedPagoda);
   
      setMembers(prevMembers => [...prevMembers, member]);
      setFamilies(prevFamilies => [...prevFamilies, family]);
      setShouldUpdateFamilies(true); 

      NotificationManager.success(CREATED_SUCCESSFULLY);

    } catch (error) {
      setShouldUpdateFamilies(true);
      NotificationManager.error(ERROR_CREATED, error.message);
    }
  };

  // xóa thành viên
  const handleDelete = async (familyId) => {
    try {
      
      const { isConfirmed } = await Swal.fire({
        title: CONFIRM_DELETED_TITLE,
        text: CONFIRM_DELETED_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy'
      });
  
      if (isConfirmed) {
        await handleDeleteFamilyAdmin(familyId);
        setFamilies(prevFamilies => prevFamilies.filter(family => family._id !== familyId));
        setShouldUpdateFamilies(true); 

        NotificationManager.success(DELETED_SUCCESSFULLY);
      }
    } catch (error) {
      NotificationManager.error(ERROR_DELETED, error);
    }
  };
  

  // loading
  if (loading) {
    return (
      <Box className="relative">
        <Box className="absolute flex justify-center items-center gap-2 top-80 left-[45%]">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='lg'
          /> <Text>Đang tải dữ liệu...</Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns='1'
        spacing={{ base: "20px", xl: "20px" }}>

        {/* Thêm gia đình */}
        <AddMember
          handleCreate={handleCreate}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          pagodas={pagodas}
        />
        {/* danh sách các gia đình */}
        <Families
          columnsData={dataMember}
          tableData={families}
          secondary={true}
          handleDelete={handleDelete}
          pagodas={pagodas}

          handlePageChange={handlePageChange}
          currentPage={page}
          totalPages={totalPages}
          handleSearch={handleSearch}
        />

      </SimpleGrid>
    </Box>
  );
}