import { format } from "date-fns";

const formatDateTime = (dateTime) => {
  if (!dateTime) {
    return ''; 
  }
  const vietnamTimeZone = 'Asia/Ho_Chi_Minh';
  return format(new Date(dateTime), 'dd/MM/yyyy HH:mm', { timeZone: vietnamTimeZone });
};

export const dataPagoda = [
  {
    Header: 'STT',
    accessor: (_, index) => index + 1,
  },
  {
    Header: "Hình ảnh",
    accessor: "image",
  },
  {
    Header: "Tên Chùa",
    accessor: "name",
  },
  {
    Header: "Trụ trì",
    accessor: "owner",
  },
  {
    Header: "Địa chỉ",
    accessor: "address",
  },
  {
    Header: "Ngày tạo",
    accessor: (row) => formatDateTime(row.createdAt),
  }
];

