//email
export const EMAIL_PHONE_NUMBER_REQUIRED = "Vui lòng nhập email hoặc số điện thoại.";
export const EMAIL_PHONE_NUMBER_INVALID = "Email hoặc số điện thoại không hợp lệ.";
//
export const INVALID_EMAIL = "Email không hợp lệ."
export const INVALID_PASSWORD = "Mật khẩu không hợp lệ."
export const INVALID_PHONE_NUMBER = "Số điện thoại không hợp lệ."


//password
export const PASSWORD_REQUIRED = "Vui lòng nhập mật khẩu.";
export const PASSWORD_MINLENGTH_INVALID = "Mật khẩu cần phải dài ít nhất 8 ký tự.";
export const PASSWORD_MAXLENGTH_INVALID = "Mật khẩu không được dài hơn 30 ký tự.";

//login 
export const LOGIN_SUCCESSFULLY = "Đăng nhập thành công!"
export const LOGIN_ERROR = 'Đã xảy ra lỗi khi đăng nhập. Vui lòng thử lại.'
export const LOGIN_FAILED = 'Đăng nhập không thành công.'
//token
export const TOKEN_ERROR ='Token không hợp lệ hoặc đã hết hạn.'

//logout
export const LOGOUT_SUCCESSFULLY ='Đăng xuất thành công!'

// check user login
export const USER_ERROR = 'Không thể kiểm tra người dùng.'

//forgot password
export const FORGOT_PASSWORD_REQUIRED = "Vui lòng nhập email.";
export const FORGOT_PASSWORD_INVALID = "Email không hợp lệ.";
export const SEND_EMAIL_SUCCESSFULLY = "Gửi gmail thành công! Hãy nhập mật khẩu mới"
export const FORGOT_PASSWORD_SUCCESSFULLY ="Đổi mật khẩu thành công!"
export const FORGOT_PASSWORD_ERROR = 'Đã xảy ra lỗi. Vui lòng thử lại.'

// input cannot be empty
export const INPUT_CANNOT_EMPTY = "Vui lòng nhập đầy đủ thông tin."

//api
export const ERROR_SERVER = "Lỗi khi lấy dữ liệu";
//create
export const CREATED_SUCCESSFULLY = "Đã thêm thành công!";
export const ERROR_CREATED = "Lỗi không tạo được";
//update
export const UPDATED_SUCCESSFULLY = "Đã cập nhât thành công!";
export const ERROR_UPDATED = "Lỗi không cập nhât được";
//update owner
export const UPDATED_OWNER_SUCCESSFULLY = "Đổi Chủ bái thành công!";
export const ERROR_UPDATED_OWNER = "Lỗi không đổi được";

//delete
export const DELETED_SUCCESSFULLY = "Đã xóa thành công!";
export const ERROR_DELETED = "Lỗi không xóa được";
export const ERROR_DELETED_MEMBER = 'Lỗi! Không tồn tại hoặc đã bị xóa bởi người dùng khác.'
// confirm delete
export const CONFIRM_DELETED_TITLE = "Bạn có chắc chắn muốn xóa?";
export const CONFIRM_DELETED_TEXT = "Hành động này không thể hoàn tác!";


// Gender Field
export const GENDER_FIELD = "Vui lòng chọn giới tính.";

// birthDay
export const ERROR_BIRTHDAY_STAFF = "Vui lòng nhập ngày sinh không hợp lệ. _Ví dụ: 01/02/2001";

export const ERROR_BIRTHDAY = "Năm sinh không vượt quá 4 số, năm sinh phải lớn hơn 1000 và không được nhập chữ";


//select pagoda
export const SELECT_FIELD = "Vui lòng chọn chùa.";

//copy, pass
export const PASS_SUCCESSFULLY = "Dán thành công!"
export const COPY_SUCCESSFULLY = "Sao chép thành công!"
export const ERROR_PASS = "Không có dữ liệu để dán!"
export const ERROR_COPY = "Không thể sao chép!"

// image pagoda
export const ERROR_IMAGE = 'Lỗi khi chuyển đổi hình ảnh : Dữ liệu ảnh không hợp lệ'

// validate max length
export const MAX_LENGTH =  'Bạn đã đạt đến giới hạn chữ.'

// The temple does not exist
export const PAGODA_NOT_EXIST =  'Đã đăng xuất, Chùa không tồn tại!'

// download file docx, excel
export const DOWNLOAD_FILE = 'Bạn có muốn tải file này?'
export const DOWNLOAD_SUCCESSFULLY = 'Tải thành công!'