

import { putTakenData } from "utils/api";
import { postTakenData, getTakenData } from "utils/api";

// login
export const login = async (payload) => {
    const response = await postTakenData('/api/v1/auth/login', payload);
    return response;
};

//kiểm tra role để chia layout giữa admin với user
export const checkUsers = async () => {
    const response = await getTakenData('/api/v1/users');
    return response;
};

// forgot password
export const forgot = async (email) => {
    const response = await postTakenData('/api/v1/auth/verify-email', {email});
    return response.data;
};

export const forgotPassword = async (id, data) => {
    const response = await putTakenData(`/api/v1/auth/forgot-password/${id}`, data);
    return response;
};




