
// Chakra imports
import { Box, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AddPagoda from "./components/AddPagoda";
import ListPagoda from "./components/ListPagoda";

import { dataPagoda } from "./variables/columnsData";
import { NotificationManager } from "react-notifications";
import { 
  handleGetPagodas, 
  handleCreatePagodas,
  handleDeletePagodas,
  handleUpdatePagodas
} from "apis/admin";
import { 
  ERROR_SERVER, 
  ERROR_DELETED, 
  CREATED_SUCCESSFULLY, 
  DELETED_SUCCESSFULLY, 
  UPDATED_SUCCESSFULLY, 
  CONFIRM_DELETED_TITLE, 
  CONFIRM_DELETED_TEXT 
} from "utils/constants";

import Swal from "sweetalert2";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDebounce } from "hooks/useDebounce";

export default function Settings() {
  
  const [pagodas, setPagodas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editPagoda, setEditPagoda] = useState(null);
  const history = useHistory()
  const [isEditing, setIsEditing] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    handleGetPagoda();
  }, [shouldUpdate, page, debouncedSearchQuery]);
  
  useEffect(() => {
    if (shouldUpdate) {
      handleGetPagoda();
      setShouldUpdate(false); 
    }
  }, [shouldUpdate]);

  // get dữ liệu
  const handleGetPagoda = async () => {
    try {
      setLoading(true);
      const limit = 8
      const {pagodaData, totalPages} = await handleGetPagodas(page + 1, limit, debouncedSearchQuery);
      
      if (!pagodaData) {
        history.push('/not-found');
        return;
      }
      
      setPagodas(pagodaData); 
      setTotalPages(totalPages)
      setLoading(false);
      history.push(`/admin/pagodas?page=${page + 1}`);
    } catch (error) {

      NotificationManager.error(ERROR_SERVER);

      setLoading(false);

    }
  };
  //click chuyển trang
  const handlePageChange = (newPage) => {
    setPage(newPage.selected);
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    setSearchQuery(searchQuery);
    setPage(0);
    setShouldUpdate(false);
  }

  //create
  const handleCreatePagoda = async (pagodaData,imageData) => {
    try {

      const pagoda = await handleCreatePagodas(pagodaData, imageData)

      setPagodas((prevPagodas) => [...prevPagodas, pagoda]);
      setShouldUpdate(true)

      NotificationManager.success(CREATED_SUCCESSFULLY);
    } catch (error) {
      setShouldUpdate(true);
      NotificationManager.error(error.message);
    }
  }

  //update
  const handleUpdatePagoda = async (updatedData, imageData) => {
    try {
      const updated = await handleUpdatePagodas(updatedData, imageData);

      
        setPagodas(prevPagodas => (
          prevPagodas.map(pagoda =>
            pagoda._id === updated._id ? updated : pagoda
          )
        ));
        setShouldUpdate(true);
        setIsEditing(false);
        setEditPagoda(null);
        NotificationManager.success(UPDATED_SUCCESSFULLY);
      
    } catch (error) {
      setShouldUpdate(true);
      NotificationManager.error(error.message);
    }
  };

  //delete
  const handleDeletePagoda = async (pagodaData) => {
    try {
      
      const { isConfirmed } = await Swal.fire({
        title: CONFIRM_DELETED_TITLE,
        text: CONFIRM_DELETED_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy'
      });
  
      if (isConfirmed) {
        await handleDeletePagodas(pagodaData)
        setPagodas(prevPagodas => prevPagodas.filter(pagoda => pagoda._id !== pagodaData));
        NotificationManager.success(DELETED_SUCCESSFULLY);
        setShouldUpdate(true);

      }

    } catch (error) {
      NotificationManager.error(ERROR_DELETED);
    }
  }

  if (loading) {
    return (
      <Box className="relative">
        <Box className="absolute flex justify-center items-center gap-2 top-80 left-[45%]">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='lg'
          /> <Text>Đang tải dữ liệu...</Text> 
        </Box>
      </Box>
    );
  }

  // edit hiển thị lại ảnh
  const handleEditPagoda = (pagoda) => {
    const imageSrc = pagoda.image;
    setSelectedImage( imageSrc ? `${imageSrc}` : '');
    setEditPagoda(pagoda);
    setIsEditing(true);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
         mb='20px' columns='1' spacing={{ base: "20px", xl: "20px" }}>

        <AddPagoda
          handleCreatePagoda={handleCreatePagoda}
          editPagoda={editPagoda} 
          isEditing={isEditing} 
          setIsEditing={setIsEditing} 
          handleUpdatePagoda={handleUpdatePagoda}
          selectedImage={selectedImage}
        />

        <ListPagoda 
          columnsData={dataPagoda} 
          handleEditPagoda={handleEditPagoda}
          tableData={pagodas}
          handleDeletePagoda={handleDeletePagoda}
          handlePageChange={handlePageChange}
          currentPage={page}
          totalPages={totalPages}
          handleSearch={handleSearch}
        />
      </SimpleGrid>
    </Box>
  );
}
