import React, { useState } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export function SearchBar(props) {

  const {searchFunction, variant, background, children, placeholder, borderRadius, ...rest } = props;
  const searchIconColor = useColorModeValue("gray.500", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");

  const [query, setQuery] = useState("");
  
  const handleSearch = async (event) => {
    const value = event.target.value;
    setQuery(value);
    // searchFunction(event);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchFunction(e);
    }
  };
  
  return (
    <>
      <InputGroup w={{ base: "100%", md: "200px" }} {...rest}>
        <InputLeftElement>
          <IconButton
                bg='inherit'
                borderRadius='inherit'
                _hover='none'
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={
                  <SearchIcon color={searchIconColor} w='15px' h='15px' />
                }
                  onClick={handleSearch}
                >
          </IconButton>
        </InputLeftElement>
        <Input
          variant='search'
          fontSize='sm'
          bg={inputBg}
          color={inputText}
          fontWeight='500'
          _placeholder={{ color: "gray.400", fontSize: "14px" }}
          borderRadius={borderRadius ? borderRadius : "30px"}
          placeholder={placeholder ? placeholder : "Tìm kiếm theo tên..."}
          value={query}
          onChange={handleSearch}
          onKeyDown={handleKeyPress}
        />
      </InputGroup>
    </>
  );
}
