import {
  Box,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Card from "components/card/Card";
import { useForm } from "react-hook-form";

import { NotificationManager } from "react-notifications";
import { InputField } from "components/inputField/input";
import { ERROR_IMAGE } from "utils/constants";


const defaultValues = {
  image: '',
  name: '',
  owner: '',
  description: '',
  address: '',
};

export default function AddPagoda({
  handleCreatePagoda,
  handleUpdatePagoda,
  editPagoda,
  isEditing,
  setIsEditing,
  selectedImage,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  
  const { register, handleSubmit, reset, setValue, getValues, formState: { errors }} = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (isEditing && editPagoda) {
      reset(defaultValues);
      Object.keys(editPagoda).forEach((key) => {
        if (key === "image") {
          // Đặt giá trị hình ảnh nếu có dữ liệu hình ảnh cũ
          setValue(key, editPagoda[key]);
        }  else {
          // Đặt giá trị mặc định cho các trường khác
          setValue(key, editPagoda[key]);
        }
      })

    } else {
      reset(defaultValues);
    }
  }, [isEditing, editPagoda, reset, setValue]);
  

  const onSubmit = async (formData) => {
    setLoading(true)
    try {
      const formData = getValues();
      let imageFile = null;
      if (formData.image && formData.image.length > 0) {
        imageFile = formData.image[0];
      }
      // const { provinces, districts, wards, ...filteredData } = formData;

      if (isEditing) {
        await handleUpdatePagoda(formData, imageFile);
      } else {
        await handleCreatePagoda(formData, imageFile);
      }
      reset(defaultValues);
      setIsEditing(false);
      setShowPopup(false);
    } catch (error) {
      NotificationManager.error(ERROR_IMAGE);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    reset(defaultValues);
    setShowPopup(false);
  };

  const handleOverlayClick = (e) => {
    // Đóng khung nếu click bên ngoài ô đen
    if (e.target.classList.contains('overlay')) {
      setShowPopup(false);
    }
  };

  return (
    <div className="flex justify-between flex-wrap mt-10">

      {(showPopup || isEditing) ? (
        <>
          <Box className="overlay fixed inset-0 bg-black bg-opacity-50 z-40" onClick={handleOverlayClick}></Box>

          <Box className="popup fixed bottom-10 left-1/2 transform -translate-x-1/2 z-50 bg-white p-5 rounded-lg shadow-md overflow-y-scroll h-5/6 w-full min-w-xl transition-transform duration-300" 
            width={{
                  base: '90%',
                  xl: '60%'
            }}
          >
            <Card width='100%'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-12 mt-2">
                  <div className="border-b border-gray-900/10 pb-6">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      {isEditing ? 'Sửa Chùa' : 'Thêm Chùa'}
                    </h2>
                    <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-5">
                    <ChooseImage
                        label="Hình ảnh:"
                        name="image"
                        register={register}
                        selectedImage={selectedImage} 
                      />
                      <InputField
                        label="Tên chùa:"
                        name="name"
                        register={register}
                        errors={errors}
                      />
                      <InputField
                        label="Trụ trì:"
                        name="owner"
                        register={register}
                        errors={errors}
                      />
                      <InputField
                        label="Thông tin:"
                        name="description"
                        register={register}
                        errors={errors}
                      />
                      <InputField
                        label="Địa chỉ:"
                        name="address"
                        register={register}
                        errors={errors}
                      />

                      </div>
                  </div>
                </div>
                <div className="mt-4 flex items-center justify-end gap-x-6">
                  <Button onClick={handleCancel} variant="ghost">
                    {isEditing ? 'Hủy cập nhật' : 'Hủy'}
                  </Button>
                  <Button
                    type='submit' 
                    bgColor="#4834d4" 
                    color='white' 
                    _hover={{color: '#4834d4', bgColor:'white', border: '0.5px solid #4834d4'}} 
                    borderRadius='5px'
                    isLoading={loading}

                  >
                    {isEditing ? 'Cập nhật' : 'Thêm'}
                  </Button>
                </div>
              </form>
            </Card>
          </Box>
        </>
      ) : (
        <div className="flex flex-col justify-end">
          <Button bgColor="#4834d4" color='white' _hover={{color: '#4834d4', bgColor:'white', border: '0.5px solid #4834d4'}} borderRadius='5px'
            onClick={() => setShowPopup(true)}>
            Thêm Chùa
          </Button>
        </div>
      )}
    </div>
  );
}



function ChooseImage({ label, name, register, selectedImage }) {
  const [imageData, setImageData] = useState( selectedImage || null); 
  
  useEffect(() => {
    if (!selectedImage) {
      setImageData(null);
    }
  }, [selectedImage]);

  const handleFileChange = (e) => {
    
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageData(event.target.result);
        register(name).onChange(e);
      };
      reader.readAsDataURL(file);
  
    }
  };
  return (
    <div className="sm:col-span-2">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <div className="text-center">
          {imageData ? (
            <img src={imageData} alt="Preview" className="mx-auto h-[350px] w-[350px] object-cover rounded-md" />
          ) : (
            <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clipRule="evenodd" />
            </svg>
          )}
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
              <span>Chọn ảnh</span>
              <input
                id="file-upload"
                name={name}
                type="file"
                className="sr-only"
                {...register(name)}
                onChange={handleFileChange}
              />
            </label>
            <p className="pl-1">từ kho dữ liệu lên</p>
          </div>
        </div>
      </div>
    </div>
  );
}


