
export const dataMember = [
  {
    Header: 'STT',
    accessor: (_, index) => index + 1,
  },
  {
    Header: "Giới tính",
    accessor: (row) => {
      if (row.gender === 'male') return 'Nam';
      if (row.gender === 'female') return 'Nữ';
      return row.gender;
    }
  },
  {
    Header: "Họ và tên",
    accessor: "name",
  },
  {
    Header: "Pháp danh",
    accessor: "nickName",
  },
  {
    Header: "Năm sinh",
    accessor: 'birthdayAndZodiac',
  },
  {
    Header: "Sao",
    accessor: "star",
  },
  {
    Header: "Nguyên quán",
    accessor: "homeTown",
  }
];

