import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import { dataMember } from "views/user/family/variables/columnsData";
import AddMember from "./components/AddMember";
import Families from "./components/Families";
import Swal from "sweetalert2";
import { handleCreateFamily, handleDeleteFamily, handleGetFamiles } from "apis/user";
import { NotificationManager } from 'react-notifications';
import { ERROR_SERVER, ERROR_CREATED, ERROR_DELETED, CREATED_SUCCESSFULLY, DELETED_SUCCESSFULLY, CONFIRM_DELETED_TEXT, CONFIRM_DELETED_TITLE } from "utils/constants";
import { useAuth } from "contexts/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDebounce } from "hooks/useDebounce";

export default function Settings() {
  const [members, setMembers] = useState([]);  
  const [families, setFamilies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [shouldUpdateFamilies, setShouldUpdateFamilies] = useState(true); 
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const history = useHistory();
  const { auth } = useAuth();
  const { pagodaId } = auth;

  useEffect(() => {
    const handleGetAllFamily = async () => {
      const limit = 8;
      try {
        setLoading(true);
        const { familyData, membersData, totalPages } = await handleGetFamiles(pagodaId, page + 1, limit, debouncedSearchQuery);

        if (!familyData || !membersData) {
          history.push('/not-found');
          return;
        }

        const combinedData = familyData.map(family => {
          const ownerIds = family.onwerId; 
          const familyMembers = membersData.filter(member => member._id === ownerIds);
          return { ...family, members: familyMembers };
        });

        setFamilies(combinedData); 
        setTotalPages(totalPages); 
        setMembers(membersData)
        setLoading(false);
        setShouldUpdateFamilies(false);
        history.push(`/user/family?page=${page + 1}`);

      } catch (error) {
        NotificationManager.error(ERROR_SERVER, error.message);
        setLoading(false);
      }
    };
      
      handleGetAllFamily();

  }, [pagodaId , history, page, shouldUpdateFamilies, debouncedSearchQuery]); 
  
  const handlePageChange = (newPage) => {
    setPage(newPage.selected);

  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    setSearchQuery(searchQuery);
    setPage(0);
    setShouldUpdateFamilies(false);
  }

  // tạo mới gia đình
  const handleCreate = async (newMember) => {
    try {
      const { family, member } = await handleCreateFamily(newMember, pagodaId);
      setMembers(prevMembers => [...prevMembers, member]);
      setFamilies(prevFamilies => [...prevFamilies, family]);

      setShouldUpdateFamilies(true); 
      NotificationManager.success(CREATED_SUCCESSFULLY);
    } catch (error) {
      setShouldUpdateFamilies(true);
      NotificationManager.error(ERROR_CREATED, error.message);
    }
  };

  // xóa gia đình
  const handleDelete = async (familyId) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: CONFIRM_DELETED_TITLE,
        text: CONFIRM_DELETED_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy'
      });

      if (isConfirmed) {
        const deleteResult = await handleDeleteFamily(familyId, pagodaId);

        if (deleteResult) {
          setFamilies(prevFamilies => prevFamilies.filter(family => family._id !== familyId));
          setShouldUpdateFamilies(true);
          NotificationManager.success(DELETED_SUCCESSFULLY);
        }
      }
    } catch (error) {
      NotificationManager.error(ERROR_DELETED, error);
    }
  };

  if (loading) {
    return (
      <Box className="relative">
        <Box className="absolute flex justify-center items-center gap-2 top-80 left-[45%]">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='lg'
          /> <Text>Đang tải dữ liệu...</Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns='1'
        spacing={{ base: "20px", xl: "20px" }}>

        <AddMember
          handleCreate={handleCreate}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
        <Families
          columnsData={dataMember}
          tableData={families}
          secondary={true}
          handleDelete={handleDelete}
          handlePageChange={handlePageChange}
          currentPage={page}
          totalPages={totalPages}
          handleSearch={handleSearch}
        />

      </SimpleGrid>
    </Box>
  );
}
