import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';

const api = axios.create({
  // baseURL: process.env.CHUA_APP_API,
  // baseURL: 'https://dev-be.quanlychua.org',
  baseURL: 'https://prod-be.quanlychua.org',
  timeout: 180000,
});

api.interceptors.request.use(async config => {
  return config;
});

api.interceptors.response.use(
  response => response.data,
  ({ message, response }) => {
    if (response?.data) {
      return Promise.reject({
        message: response.data.message || response.data.error || message,
        code: response.data.code ?? -1,
        status: response?.status,
      });
    }
    return Promise.reject({
      message,
      code: response?.status ?? -1,
      status: response?.status,
    });
  },
);

const getTakenData = async (url, params = {}) => {
  let token = null;
  token = localStorage.getItem('@auth');
  try {
    let headers = token ? { Authorization: 'Bearer ' + token } : {};
    const result = await api.get(url, {
      params: params,
      headers,
    });
    return result;
  } catch (e) {
    throw e;
  }
};

const postTakenData = async (url, data = {}) => {
  let token = null;
  token = localStorage.getItem('@auth');
  try {
    const result = await api.post(url, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
      },
    });
    return result;
  } catch (e) {
    throw e;
  }
};

const putTakenData = async (url, data = {}, upload) => {
  let token = null;
  token = localStorage.getItem('@auth');
  try {
    let result;
    if (upload) {
      const formData = new FormData();
      formData.append('upload', data);
      result = await api.put(url, formData, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      });
    } else {
  
      result = await api.put(url, data, {
        headers: { Authorization: 'Bearer ' + token },
      });
    }
    return result;
  } catch (e) {
    throw e;
  }
};

const deleteTakenData = async (url, params = {}) => {
  let token = null;
  token = localStorage.getItem('@auth');
  try {
    const result = await api.delete(url, {
      params: params,
      headers: { Authorization: 'Bearer ' + token },
    });
    return result;
  } catch (e) {
    throw e;
  }
};


const patchTakenData = async (url, data = {}) => {
  let token = localStorage.getItem('@auth');
  try {
    const result = await api.patch(url, data, {
      headers: { Authorization: 'Bearer ' + token },
    });
    return result;
  } catch (e) {
    throw e;
  }
};



export { getTakenData, postTakenData, putTakenData, deleteTakenData, patchTakenData };

export default api;
