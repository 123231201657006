import React, { useEffect, useState } from "react";
import { Flex, useColorModeValue, Text, Box } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { useAuth } from "contexts/AuthContext";
import { handleChoosePagodas } from "apis/admin";
import imgAdminPagoda from 'assets/img/logo-pagoda.jpg'

export function SidebarBrand() {
  const { auth  } = useAuth();
	const { role, pagodaId  } = auth;
  const [pagodaName, setPagodaName] = useState('');
  const [pagodaImg, setPagodaImg] = useState('');

	let secondaryText = useColorModeValue('gray.700', 'white');

  useEffect(() => {

    const fetchPagodaData = async () => {
      try {
        const response = await handleChoosePagodas(); 
        const pagoda = response.find(p => p._id === pagodaId);
        if (pagoda) {
          setPagodaName(pagoda.name);
          setPagodaImg(pagoda.image)
        }

      } catch (error) {
        console.error("Error fetching pagoda data", error);
      }
    };

    fetchPagodaData();
  }, [pagodaId]);

  return (
    <Flex align='center' direction='column'>
      <Text  as='b' color={secondaryText} textTransform="uppercase" my='32px' fontWeight="700" fontSize={{base: "lg", xl: "xl"}}>
        {role === 'admin' ? 
          <Box className="flex flex-col justify-center items-center">
            <Box> <img className="h-[70px] rounded-lg" src={imgAdminPagoda}/> </Box>
            <Box className="text-center" fontSize='initial' mt='10px'>Tổ chức Giáo Hội Phật Giáo Việt Nam</Box>
          </Box> 
        :
						<Box className="flex flex-col justify-center items-center">
              <Box>{pagodaImg ? <img className="h-[150px] rounded-lg mb-3 ring-2 ring-gray-300 shadow-lg shadow-gray-400" src={pagodaImg}/> : ''}</Box>
              <Box mt='15px' maxW='164px' className="text-center">{pagodaName}</Box>
            </Box> 
				}
      </Text>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
