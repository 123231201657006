import React from "react";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";

const Pagination = ({ pageCount, currentPage, onPageChange }) => {
  
  return (
    <div className="pagination-container">
      <ReactPaginate
        previousLabel={"Trang trước"}
        nextLabel={"Trang sau"}
        breakLabel={". . ."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName={" pagination flex justify-center list-none p-0"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        forcePage={currentPage}
        previousLinkClassName={`px-2 py-1 border border-gray-300 mr-1 rounded-lg  ${currentPage === 0 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-700 hover:bg-gray-100'}`}
        nextLinkClassName={`px-2 py-1 border border-gray-300 ml-1 rounded-lg  ${currentPage === pageCount - 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-700 hover:bg-gray-100'}`}
        pageLinkClassName={"m-0.5 px-3 py-1 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100"}
        breakLinkClassName={"px-2 py-1 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100"}
        activeLinkClassName={"bg-blue-700 text-white hover:bg-blue-700 "}
      />
    </div>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
