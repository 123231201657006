import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import DefaultAuth from "layouts/auth/Default";
import validator from "validator";

import { 
  EMAIL_PHONE_NUMBER_INVALID,
  EMAIL_PHONE_NUMBER_REQUIRED,
  PASSWORD_REQUIRED,
  PASSWORD_MINLENGTH_INVALID,
  PASSWORD_MAXLENGTH_INVALID,
  LOGIN_FAILED, LOGIN_ERROR, TOKEN_ERROR, USER_ERROR, LOGIN_SUCCESSFULLY

} from "utils/constants";

import { login, checkUsers } from "apis/auth";
import { useAuth } from "contexts/AuthContext";
import { NotificationManager } from "react-notifications";

function Login() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { setAuth } = useAuth();

  const [isEmail, setIsEmail] = useState(true);

  // dùng khi login vào home, khi có token r dù có click back cũng trở lại home
  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('@auth');
      if (token) {
        try {
          const { role } = await handleCheckUser(token);
          if (role === 'admin') {
            history.push('/admin');
          } else if (role === 'staff') {
            history.push('/user');
          }
        } catch (error) {
          throw new Error(TOKEN_ERROR);
        }
      }
    };

    checkToken();
  }, [history]);

  // validate  người dùng chỉ cần nhập sdt bth
  const formatPhoneNumber = (value) => {
    const digits = value.replace(/\D/g, ''); 
    if (digits.length >= 10 && digits.startsWith('0')) {
      const countryCode = '+84';
      const formatted = `${countryCode} ${digits.slice(1, 4)} ${digits.slice(4, 7)} ${digits.slice(7, 10)}`;
      return formatted;
    }
    return value;
  };

  // api login
  const handleLogin = async (payload) => {
    try {
      const responseData = await login(payload);
      if (!responseData) {
        throw new Error(LOGIN_FAILED);
      }
      const { accessToken } = responseData.data;
      localStorage.setItem('@auth', accessToken);
      return accessToken;
    } catch (error) {
      throw new Error(error.message || LOGIN_ERROR);
    }
  };

  //api kiểm tra admin và staff
  const handleCheckUser = async (accessToken) => {
    try {
      const roleResponse = await checkUsers(accessToken);
      const { role, pagodaId } = roleResponse.data;
      return { role, pagodaId };
    } catch (error) {
      throw new Error(USER_ERROR);
    }
  };

  // submit
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const { username, password } = data;

      let trimUsername = username.trim().toLowerCase();
      const trimPassword = password;

      if (!isEmail) {
        trimUsername = formatPhoneNumber(trimUsername);
      }

      const payload = {
        [isEmail ? 'email' : 'phoneNumber']: trimUsername,
        password: trimPassword
      };

      const accessToken = await handleLogin(payload);
      const { role, pagodaId } = await handleCheckUser(accessToken);

      localStorage.setItem('@auth', accessToken);
      localStorage.setItem('role', role);
      if (role === 'staff') {
        localStorage.setItem('pagodaId', pagodaId);
      }

      setAuth({ accessToken, role, pagodaId });
      
      if (role === 'admin') {
        history.push('/admin');
      } else if (role === 'staff') {
        history.push('/user');
      } else {
        throw new Error('Role không xác định.');
      }

      NotificationManager.success(LOGIN_SUCCESSFULLY);
      
    } catch (error) {
      setLoading(false);
      setError(error.message || LOGIN_ERROR);
    }
  };

  // validate  người dùng chỉ cần nhập sdt bth
  const validatePhoneNumber = (value) => {
    const phoneNumberRegex = /^\+84 \d{3} \d{3} \d{3}$/;
    return phoneNumberRegex.test(value) || EMAIL_PHONE_NUMBER_INVALID;
  };

  return (
    <DefaultAuth >

      <Flex
        maxW={{ base: "100%" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "40px" }}
      >
        <Box
            className=" border-2 p-10"
            borderRadius='15px'
        >
        <Box >
          <Heading color={textColor} fontSize='25px' mb='40px' textAlign='center'>
            {error ? <Box color='red' fontSize='20px' mb='45px'>{error}</Box> : 'Đăng Nhập'}
          </Heading>
        </Box>
          <Flex alignItems='center' w={{ base: "100%", md: "520px" }} mt='2px' mb='30px' borderBottom='1px solid #b2bec3'>
                  <Box
                    cursor='pointer'
                    variant={isEmail ? 'solid' : 'outline'}
                    onClick={() => setIsEmail(true)}
                    mr='2'
                    p='3px 16px'
                    borderRadius='3px'
                    w='100%'
                    textAlign='center'
                    fontSize='lg'
                    bgColor={isEmail ? '#dfe4ea' : 'transparent'}
                    color={isEmail ? 'gray.600' : 'black'}
                    _hover={{bgColor: !isEmail ? '#f1f2f6' : undefined}}
                    pointerEvents={isEmail ? 'none' : 'auto'}
                  >
                    Email
                  </Box>
                  <span className="bg-slate-400 h-8 w-0.5"></span>
                  <Box
                    cursor='pointer'
                    variant={!isEmail ? 'solid' : 'outline'}
                    onClick={() => setIsEmail(false)}
                    ml='2'
                    p='3px 16px'
                    borderRadius='3px'
                    fontSize='lg'
                    w='100%'
                    textAlign='center'
                    bgColor={!isEmail ? '#dfe4ea' : 'transparent'}
                    color={!isEmail ? 'gray.600' : 'black'}
                    _hover={{bgColor: isEmail ? '#f1f2f6' : undefined}}
                    pointerEvents={!isEmail ? 'none' : 'auto'}
                  >
                    Số điện thoại
                  </Box>
          </Flex>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "520px" }}
            maxW='100%'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'
                >
                  {isEmail ? 'Email' : 'Số điện thoại'}<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder={isEmail ? 'Vui lòng nhập email.' : 'Vui lòng nhập số điện thoại.'}
                  mb='5px'
                  fontWeight='500'
                  size='lg'
                  {...register('username', {
                    required: EMAIL_PHONE_NUMBER_REQUIRED,
                    validate: value => {
                      const trimmedValue = value.trim();
                      if (isEmail) {
                        return validator.isEmail(trimmedValue) || EMAIL_PHONE_NUMBER_INVALID;
                      } else {
                        return validatePhoneNumber(formatPhoneNumber(trimmedValue));
                      }
                    }
                  })}
                />
                {errors.username && <Text color="red.500">{errors.username.message}</Text>}
                <FormLabel
                  ms='4px'
                  mt='24px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'
                >
                  Mật khẩu<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    fontSize='sm'
                    placeholder='Nhập đầy đủ kí tự mật khẩu'
                    mb='5px'
                    size='lg'
                    type={show ? "text" : "password"}
                    variant='auth'
                    {...register('password', {
                      required: PASSWORD_REQUIRED,
                      minLength: {
                        value: 8,
                        message: PASSWORD_MINLENGTH_INVALID
                      },
                      maxLength: {
                        value: 30,
                        message: PASSWORD_MAXLENGTH_INVALID
                      }
                    })}
                  />
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                  </InputRightElement>
                </InputGroup>
                {errors.password && <Text color="red.500">{errors.password.message}</Text>}
                <Flex justifyContent='space-between' align='center' mb='24px' mt='24px'>
                  <NavLink to='/forgot-password'>
                    <Text
                      color={textColorBrand}
                      fontSize='sm'
                      w='124px'
                      fontWeight='500'
                    >
                      Quên mật khẩu?
                    </Text>
                  </NavLink>
                </Flex>
                <Button
                  type="submit"
                  fontSize='sm'
                  variant='brand'
                  fontWeight='500'
                  w='100%'
                  h='50'
                  isLoading={loading}
                >
                  Đăng nhập 
                </Button>
              </FormControl>
            </form>
          </Flex>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default Login;
