import * as XLSX from 'xlsx';

export const generateExcel = (family, zodiac) => {
  const owner = zodiac.find(member => member._id === family.onwerId) || {};

  // Tạo worksheet
  const data = [
    ["", "", "DÂNG LỄ CẦU AN", "", ""],
    [],
    [],
    ["Chủ bái:", owner.name || '', ""],
    ["Pháp danh:", owner.nickName || '', ""],
    ["Nguyên quán:", owner.homeTown || ''],
    ["Địa chỉ:", owner.address || ''],
    [],
    ["GIỚI TÍNH", "HỌ VÀ TÊN", "PHÁP DANH", "NĂM SINH", "TUỔI", "SAO"],
    ...zodiac.map(member => [
      member.gender === 'male' ? 'Nam' : 'Nữ',
      member.name || '',
      member.nickName || '',
      member.birthdayAndZodiac || '',
      member.age || '',
      member.star || ''
    ])
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(data);


  worksheet["!cols"] = [
    { width: 15 }, 
    { width: 20 }, 
    { width: 20 },
    { width: 20 },
    { width: 10 },
    { width: 20 },
  ];

  // Tạo workbook và ghi vào file
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  XLSX.writeFile(workbook, "Phiếu-cầu-an.xlsx");
  
};
