import React, {useEffect, useMemo, useState } from "react";
import { Box, Flex, Text, Tooltip, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue, ModalOverlay, Modal, ModalContent, ModalBody, Image, IconButton } from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import Card from "components/card/Card";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Pagination from "utils/customPagination";
import DragScroll from "react-indiana-drag-scroll";
import defaultImageUrl from 'assets/img/pagoda-default.jpg'

import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { CloseIcon } from "@chakra-ui/icons";
import NoDataSvg from 'assets/icons/no-data.svg'

export default function ListPagoda({ tableData, columnsData, secondary, handleEditPagoda, handleDeletePagoda, handlePageChange, currentPage, totalPages, handleSearch }) {
  
  const history = useHistory();

  const handleViewDetails = (staffs) => {
    if (!staffs || !staffs._id) {
      return;
    }
    history.push(`/admin/staffs/staff-of-pagoda/${staffs._id}`, { name: 'Danh Sách Sư Quản Lí' });
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage
  } = tableInstance;
  
  useEffect(() => {
    gotoPage(currentPage);
  }, [currentPage, gotoPage]);

  return (
    <Card direction="column" w="100%" px="0px" overflowX="hidden">
   
        <Flex px="25px" justify="space-between" mb="20px" align="center" flexWrap="wrap">
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Danh Sách Chùa
          </Text>
          <SearchBar
            searchFunction={handleSearch}
            w={{ base: "100%", md: "50%" }} 
            mt={{ base: "15px", md: "0px" }} 
            mb={secondary ? { base: "10px", md: "unset" } : "unset"} 
            me="10px" borderRadius="30px" 
          />
        </Flex>
        <DragScroll>
          {data.length === 0 ? (
                <Box px="25px" py="20vh" >
                 <Text color='#7f8c8d' fontSize="18px" fontWeight='500' className="flex justify-center flex-col items-center " >
                  <img src={NoDataSvg}  alt="No data icon"/>
                   <Box>Chưa Có Dữ Liệu</Box>
                 </Text>
               </Box>
          ) : (
          <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th {...column.getHeaderProps(column.getSortByToggleProps())} pe="10px" key={index} borderColor={borderColor}>
                      <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400" className="text-nowrap"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                  <Th borderColor={borderColor} pe="10px">
                    <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                      Chi tiết
                    </Flex>
                  </Th>
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                //tính toán đúng số lượng item
                const actualRowIndex = index + 1 + currentPage * 8;
                return (
                  <Tr {...row.getRowProps()} key={index}  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                    {row.cells.map((cell, index) => ( 
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                      <Flex align="center">
                          {cell.column.id === 'image' ? (
                            <Image 
                              src={cell.value ? `${cell.value}` : defaultImageUrl} 
                              alt="Hình ảnh" 
                              onClick={cell.value ? () => openModal(`${cell.value}`) : null} 
                              minWidth='120px'
                              width='120px'
                              height='80px'
                              borderRadius='10px'
                              objectFit='cover'
                              cursor='pointer'
                              css={cell.value ? {} : { filter: 'grayscale(100%)', opacity: 0.5, minWidth :'125px' }}
                            />
                    
                          ) : (
                            <Text color={textColor} fontSize="sm" fontWeight="400" 
                            >
                              {cell.column.render("Header") === 'STT' ? actualRowIndex : 
                                cell.value && typeof cell.value === 'string' ?
                                cell.value.charAt(0).toUpperCase() + cell.value.slice(1)
                                :
                                cell.value
                              }
                            </Text>
                          )}
                        </Flex>
                      </Td>
                    ))}
                    <Td minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                      <Box className="flex justify-start items-center gap-2">
                        <Box onClick={() => {handleViewDetails(row.original)}}  className="cursor-pointer bg-cyan-600 p-2 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-cyan-600 border-2 hover:border-cyan-700">
                          <Tooltip label="Xem Sư Quản Lí">
                            <Box fontSize="lg">
                              Xem
                            </Box>
                          </Tooltip>
                        </Box>
                        <Tooltip label="Chỉnh sửa chi tiết chùa">
                          <Box cursor="pointer" onClick={() => handleEditPagoda(row.original)} 
                            className="cursor-pointer bg-cyan-700 p-[9px] rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-cyan-600 border-2 hover:border-cyan-700"  fontSize="lg">
                            <FaEdit/>
                          </Box>
                        </Tooltip>
                        <Tooltip label="Xóa chùa">
                          <Box onClick={() => handleDeletePagoda(row.original._id)} 
                          className="cursor-pointer bg-red-600 p-2 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-red-600 border-2 hover:border-red-700"  fontSize="lg">
                            Xóa
                          </Box>
                        </Tooltip>
                      </Box>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          )}
        </DragScroll>
      {data.length > 0 && (
        <Pagination
          pageCount={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}

      {/* click show ảnh */}
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent height='80vh' maxWidth='50%' backgroundColor='unset' boxShadow='unset'>
          <ModalBody className="flex justify-center items-center">
            <IconButton
              aria-label="Close modal"
              icon={<CloseIcon />}
              onClick={closeModal}
              position="absolute"
              top="10px"
              right="10px"
              backgroundColor="white"
              borderRadius="full"
          />
            {<Image src={selectedImage} alt="Hình ảnh" maxW="100%" maxH="70vh" borderRadius='13px' border='3px solid white' />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
}
