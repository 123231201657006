import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { handleChoosePagodas } from "apis/admin";
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { useAuth } from 'contexts/AuthContext';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { routesAdmin } from 'routes';
import { routesUser } from 'routes';
import Logout from 'views/auth/logout';
import imgPagodaDefault from 'assets/img/logo-pagoda.jpg'
export default function HeaderLinks(props) {
  const { secondary } = props;
  // Chakra Color Mode
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const { auth  } = useAuth();
	const { role, pagodaId } = auth;

	const [pagodaName, setPagodaName] = useState('');
  const [pagodaDescription, setPagodaDescription] = useState('');
  const [pagodaImg, setPagodaImg] = useState('');

  useEffect(() => {

    const fetchPagodaData = async () => {
      try {
        const response = await handleChoosePagodas(); 
        const pagoda = response.find(p => p._id === pagodaId);
        if (pagoda) {
          setPagodaName(pagoda.name);
          setPagodaDescription(pagoda.description)
          setPagodaImg(pagoda.image)
        }

      } catch (error) {
        console.error("Error fetching pagoda data", error);
      }
    };

    fetchPagodaData();
  }, [pagodaId]);

	return (
		<div className='flex justify-end'>
			<Flex
				w={{ sm: '35%', md: 'auto' }}
				alignItems="center"
				justifyContent='space-around'
				flexDirection="row"
				bg={menuBg}
				flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
				p="10px"
				borderRadius="30px"
				boxShadow={shadow}>

				{/* responsive */}
				{role === 'admin' ? (
        <SidebarResponsive routes={routesAdmin} />
				) : role === 'staff' ? (
					<SidebarResponsive routes={routesUser} />
				) : null}
				{/* end responsive */}
		
				<Menu>
					<MenuButton p="0px">
						<Avatar
							_hover={{ cursor: 'pointer' }}
							w="40px"
							h="40px"
							src={
								role === 'admin' ? (
									`${imgPagodaDefault}`
								) : role === 'staff' ? (
									`${pagodaImg}`
								) : null
							}
						/>
					</MenuButton>
					<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
						<Flex w="100%" mb="0px">
							<Text
								pt="16px"
								pb="10px"
								w="100%"
								borderBottom="1px solid"
								borderColor={borderColor}
								fontSize="sm"
								fontWeight="700"
								textAlign='center'
								color={textColor}>
								{role === 'admin' ? (
									'👋 Chào, Admin'
								) : role === 'staff' ? (
									<Box className="p-2">👋 Chào Thầy, {pagodaName}</Box>
								) : null}
							</Text>
						</Flex>
						{role === 'staff' ? (
							<Flex w="300px" mb="0px" p="10px">
								<Text
									w="100%"
									borderBottom="1px solid"
									borderColor={borderColor}
									fontSize="sm"
									fontWeight="500"
									textAlign='justify'
									color={textColor}>
									<Box className="px-2 font-bold">Thông tin chùa:</Box>
									<Box className="p-2">{pagodaDescription}</Box>
								</Text>
							</Flex>
						) : ''}
						<Flex p="10px">
							<MenuItem
								justifyContent='center'
								>
									<Logout/> 
							</MenuItem>
						</Flex>
					</MenuList>
				</Menu>
			</Flex>
		</div>
	);
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
