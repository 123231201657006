import { postTakenData, deleteTakenData, getTakenData, patchTakenData, putTakenData } from "utils/api";
import { ERROR_SERVER } from "utils/constants";
import imageCompression from 'browser-image-compression';

//lấy dữ liệu
export const handleGetPagodas = async ( page, limit, searchQuery) => {
  try {
    
    const pagodaResponse = await getTakenData(`/api/v1/pagodas?name=${searchQuery}&page=${page}&limit=${limit}`); 
    const pagodaData = pagodaResponse.data.items
    const totalPages = pagodaResponse.data.totalPages
    
    return {pagodaData, totalPages}
    
  } catch (error) {
    console.error(ERROR_SERVER, error);
  }
};

// select
export const handleChoosePagodas = async () => {
  try {
    
    const pagodaResponse = await getTakenData(`/api/v1/pagodas`); 
    const pagodaData = pagodaResponse.data.items
    return pagodaData
    
  } catch (error) {
    console.error(ERROR_SERVER, error);
  }
};

///PAGODAS

// Hàm nén hình ảnh
const compressImage = async (imageData, quality = 0.7) => {
  try {
    if (!imageData || !(imageData instanceof Blob)) {
      throw new Error('Dữ liệu ảnh không hợp lệ');
    }

    const options = {
      maxSizeMB: 1, 
      maxWidthOrHeight: 800,
      useWebWorker: true
    };

    const compressedImage = await imageCompression(imageData, options);
    return compressedImage;
  } catch (error) {
    console.error(ERROR_SERVER);
  }
}


// tạo chùa
export const handleCreatePagodas = async (pagodaData, imageData) => {
  try {
    
    let imageUrl = '';

    if (imageData) {
      const compressedImage = await compressImage(imageData);

      const formData = new FormData();
      formData.append('image', compressedImage, 'image.jpg');
      
      const imageResponse = await postTakenData(`/api/v1/pagodas/upload-image`, formData);

      imageUrl = imageResponse.data;
    }
  
    // nếu ko có ảnh thì rỗng
    pagodaData.image = imageUrl || '';

    const pagoda = await postTakenData(`/api/v1/pagodas`, pagodaData);

    return pagoda;

  } catch (error) {
    console.error(ERROR_SERVER, error);

  }
};

// xóa chùa
export const handleDeletePagodas = async (pagodaData) => {
  try {

    const pagodaRes = await deleteTakenData(`/api/v1/pagodas/pagoda-management/${pagodaData}`)

    return pagodaRes
    
  } catch (error) {
    console.error(ERROR_SERVER, error);

  }
};


// cập nhật chùa 
export const handleUpdatePagodas = async (pagodaData, imageData) => {
  try {
 
    let imageUrl = pagodaData.image || '';
    // nếu có ảnh mới
    if (imageData instanceof Blob) {
      const compressedImage = await compressImage(imageData);

      const formData = new FormData();
      formData.append('image', compressedImage, 'image.jpg');
      
      const imageResponse = await postTakenData(`/api/v1/pagodas/upload-image`, formData);
      imageUrl = imageResponse.data; 
    }

    const { __v, _id, createdAt, updatedAt, deletedAt, ...requestData } = pagodaData;

    const updatedData = { ...requestData, image: imageUrl };

    const pagodaRes = await putTakenData(`/api/v1/pagodas/${pagodaData._id}`, updatedData)

    return pagodaRes.data
    
  } catch (error) {
    if (error.response && error.response.status === 409) {
      const { data } = error.response;
      const { message } = data;
      console.error('Lỗi 409:', message);
      throw error;
    } else {
      console.error(ERROR_SERVER, error.message);
      throw error;
    }

  }
};

/// END PAGODAS


/// STAFFS
// get sư thuộc chùa nào
export const handleGetStaffsOfPagoda = async (pagodaId) => {
  try {

    const staffResponse = await getTakenData(`/api/v1/staffs/staff-of-pagoda/${pagodaId}`);
    const staffDataRes = staffResponse.data
  
    return staffDataRes
    
  } catch (error) {

    console.error(ERROR_SERVER, error);

  }
};

// tạo sư
export const handleCreateStaffs = async (pagodaId, staffData) => {
  try {

    const data = { ...staffData, pagodaId };

    const staffResponse = await postTakenData(`/api/v1/staffs`, data);

    return staffResponse.data

  } catch (error) {

    if (error.response && error.response.status === 409) {
      const { data } = error.response;
      const { message } = data;
      console.error('Lỗi 409:', message);
      throw error;
    } else if(error.status === 400){
      throw new Error('Số điện thoại không đúng định dạng! Thêm thất bại');
    }else {
      console.error(ERROR_SERVER, error.message);
      throw error;
    }

  }
};

export const handleUpdateStaffs = async (updateStaff) => {
  try {

    const { __v, _id, createdAt, updatedAt, ...requestData } = updateStaff;
    const updateRes = await putTakenData(`/api/v1/staffs/${updateStaff._id}`, requestData);

    return updateRes.data;
    
  } catch (error) {
    if (error.response && error.response.status === 409) {
      const { data } = error.response;
      const { message } = data;
      console.error('Lỗi 409:', message);
      throw error;
    } else if(error.status === 400){
      throw new Error('Số điện thoại không đúng định dạng! Cập nhật thất bại');
    }else  {
      console.error(ERROR_SERVER, error.message);
      throw error;
    }

  }
};

export const handleDeleteStaffs = async (staffData) => {
  try {

    const pagodaRes = await deleteTakenData(`/api/v1/staffs/${staffData}`)

    return pagodaRes
    
  } catch (error) {
    console.error(ERROR_SERVER, error);

  }
  };




// FAMILIES
export const handleGetFamilesAdmin = async ( page, limit, searchQuery) => {
  
  try {
    const [familyResponse, membersResponse] = await Promise.all([
      getTakenData(`/api/v1/family?name=${searchQuery}&page=${page}&limit=${limit}`),
      getTakenData(`/api/v1/members`)
      ]);
    
    return {
      familyData: familyResponse.data.items || [],
      totalPages: familyResponse.data.totalPages || [],
      membersData: membersResponse.data.items || [],
    };
  } catch (error) {
    console.error(ERROR_SERVER, error);
    
  }
};


//tạo thành viên đầu tiên ở trang families sẽ mặc định là owner
export const handleCreateFamilyAdmin = async (newMember, pagodaId) => {
  try {
    let member = {};

    // Nếu có _id từ clipboard, sử dụng thông tin này
    if (newMember._id) {
      member = newMember;
    } else {
      // Nếu không có _id từ clipboard, tạo mới thành viên
      const memberRes = await postTakenData(`/api/v1/members`, { ...newMember, pagodaId });
      member = memberRes.data;
    }

    // Thêm thành viên vào gia đình
    if (member._id) {
      const familyRes = await postTakenData(`/api/v1/family/${member._id}`, { pagodaId });
      const family = familyRes.data;

      await patchTakenData(`/api/v1/family/${family._id}/add-family/${member._id}`);

      return { family, member };
    } else {
      return null;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = error.response;
      const { message } = data;
      console.error('Lỗi 400:', message);
      throw error;
    } else {
      console.error(ERROR_SERVER, error.message);
      throw error;
    }
  }
};

// xóa gia đình
export const handleDeleteFamilyAdmin = async (familyId, pagodaId) => {
  try {

    const deleteFamilyRes = await deleteTakenData(`/api/v1/family/${familyId}`, { pagodaId });

    return deleteFamilyRes;

  } catch (error) {
    console.error(ERROR_SERVER, error);

  }
};
// END FAMILIES


/// MEMBERS
// lấy dữ liệu
export const handleGetFamilyAndMembersAdmin = async (familyId) => {
    try {
      const [familyRes, membersRes] = await Promise.all([
        getTakenData(`/api/v1/family/${familyId}`),
        getTakenData(`/api/v1/members`)
      ]);
    
      
      const familyData = familyRes.data;
    
      if (!Array.isArray(familyData.membersId)) {
        familyData.membersId = [];
      }
    
      return { family: familyData, members: membersRes.data.items };
    } catch (error) {
      console.error('error', error);
    }
  };

  
  //show năm cùng con giáp lên ui
  export const handleGetYearBirthdayAdmin = async (familyId) => {
    try {

      const birthdayZodiac = await getTakenData(`/api/v1/family/members-zodiac/${familyId}`)
  

      const membersZodiacs = birthdayZodiac?.data?.members

      return membersZodiacs
      
    } catch (error) {
      console.error(ERROR_SERVER, error);
    }
  };
  
  // tạo thành viên
  export const handleCreateMemberAdmin = async (newMember, familyId, pagodaId) => {
  
  try {
    let memberId;

    if (newMember._id) {
      // Nếu có _id, sử dụng _id để thêm thành viên vào gia đình
      memberId = newMember._id;
    } else {
      // Nếu không có _id, tạo thành viên mới
      const memberRes = await postTakenData(`/api/v1/members`, { ...newMember, pagodaId });
      memberId = memberRes.data._id;
    }

    // Thêm thành viên vào gia đình 
    await patchTakenData(`/api/v1/family/${familyId}/add-family/${memberId}`);

    return { member: newMember  };
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { data } = error.response;
      const { message } = data;
      console.error('Lỗi 400:', message);
      throw error;
    } else {
      console.error(ERROR_SERVER, error.message);
      throw error;
    }
  
  }
  };
  
  // cập nhật thành viên
  export const handleUpdateMemberAdmin = async (updatedMember, familyId, family) => {
  try {

      const { __v, _id, createdAt, updatedAt, ...requestData } = updatedMember;
  
      const res = await putTakenData(`/api/v1/members/${updatedMember._id}`, requestData);
      if (updatedMember._id === family.onwerId) {
        await putTakenData(`/api/v1/family/updateNameOwner/${familyId}?idOwner=${updatedMember._id}`, familyId);
      }

      return res.data;
  
  } catch (error) {
    if (error.response && error.response.status === 409) {
      const { data } = error.response;
      const { message } = data;
      console.error('Lỗi 409:', message);
      throw error;
    } else {
      console.error(ERROR_SERVER, error.message);
      throw error;
    }
  
  }
  };
  
  // xóa thành viên
  export const handleDeleteMemberAdmin = async (memberId, family, pagodaId) => {
    try {
        
        const memberDelete = await deleteTakenData(`/api/v1/members/${memberId}`, {pagodaId});
        await patchTakenData(`/api/v1/family/${family._id}/remove-member/${memberId}`);
  
        return memberDelete ;

    } catch (error) {
      console.error(ERROR_SERVER, error);
    }
  };
  
  // cập nhật , thay đổi ai là chủ bái
  export const handleUpdateOwnerAdmin = async (newOwnerId, currentOwnerId, memberIdToSwitch, familyId, pagodaId) => {
  
    try {
      
      const requestData = {
        onwerId: newOwnerId, 
        pagodaId
      };
    
      const isOwner = await putTakenData(`/api/v1/family/${familyId}`, requestData);

    //sửa ngày 22/07/2024
      await putTakenData(`/api/v1/family/updateNameOwner/${familyId}?idOwner=${newOwnerId}`, familyId);
    //end sửa ngày 22/07/2024
      return isOwner;
    
    } catch (error) {
      console.error(ERROR_SERVER, error);
    
    }
  };
  
  /// END MEMBERS
  
