import React, { useEffect, useRef, useState } from 'react';
import { Box, Spinner, Stack, StackDivider, Table, Tbody, Text, Thead, Tr } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { DOWNLOAD_FILE, DOWNLOAD_SUCCESSFULLY, ERROR_SERVER } from 'utils/constants';
import { handleGetFamilesAndMemberPrint } from 'apis/printDocument';
import { useReactToPrint } from 'react-to-print';
import Card from 'components/card/Card';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { GrLinkPrevious } from 'react-icons/gr';
import { useAuth } from 'contexts/AuthContext';
import './print.css';
import { generateDocx } from './generateDocx';
import { generateExcel } from './generateExcel';
import Swal from 'sweetalert2';

const PrintComponent = () => {
  const { id } = useParams();
  const location = useLocation();
  const { family: familyState } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [family, setFamily] = useState(familyState || null);
  const [zodiac, setZodiac] = useState([]);
  const { auth } = useAuth();
  const { role } = auth;
  const history = useHistory();
  const componentRef = useRef();

  const handleViewDetails = () => {
    if (role === 'staff') {
      history.push(`/user/family/${id}`, { name: 'Danh Sách Thành Viên' });
    } else {
      history.push(`/admin/families/${id}`, { name: 'Danh Sách Thành Viên' });
    }
  }

  useEffect(() => {
    const handleGetAllFamily = async () => {
      try {
        const result = await handleGetFamilesAndMemberPrint(id);
        if (result) {
          const { family, zodiac } = result;
          setFamily(family);
          setZodiac(zodiac);
        } else {
          throw new Error("No data received from API");
        }
      } catch (error) {
        NotificationManager.error(ERROR_SERVER, error);
      } finally {
        setLoading(false);
      }
    };

    handleGetAllFamily();
  }, [id]);



  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //docx
  const handlePrintDocx = async () => {
    const { isConfirmed } = await Swal.fire({
      title: DOWNLOAD_FILE,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Tải xuống',
      cancelButtonText: 'Hủy'
    });

    if (isConfirmed) {
      generateDocx(family, zodiac);
      NotificationManager.success(DOWNLOAD_SUCCESSFULLY);
    }
  };;

  //excel
  const handlePrintExcel = async () => {
    const { isConfirmed } = await Swal.fire({
      title: DOWNLOAD_FILE,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Tải xuống',
      cancelButtonText: 'Hủy'
    });

    if (isConfirmed) {
      generateExcel(family, zodiac);
      NotificationManager.success(DOWNLOAD_SUCCESSFULLY);
    }
  };

  

  if (loading) {
    return (
      <Box className="relative">
        <Box className="absolute flex justify-center items-center gap-2 top-80 left-[45%]">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />{' '}
          <Text>Đang tải dữ liệu...</Text>
        </Box>
      </Box>
    );
  }


  return (
    <Card className="m-[100px_auto] shadow-lg border border-gray-300 rounded-md">
      <Box ref={componentRef} className='w-[95%] m-[30px_auto] '>
        <div className="print-header">
          <h2 className="text-2xl text-center font-bold mt-1 mb-15"> DÂNG LỄ CẦU AN </h2>
          <Box className="print-info mt-11 mx-5">
            <Stack divider={<StackDivider />} spacing="4px" fontSize="lg">
              {[
                { label: 'Chủ bái', property: 'name' },
                { label: 'Pháp danh', property: 'nickName' },
                { label: 'Nguyên quán', property: 'homeTown' },
                { label: 'Địa chỉ', property: 'address' },
              ].map((item, index) => (
                <Box key={index} className='flex items-center gap-4'>
                  <Text fontWeight='700'>{item.label}: </Text>
                  <Text textTransform='capitalize'>
                    {zodiac.find(member => member._id === family.onwerId)?.[item.property] || 'N/A'}
                  </Text>
                </Box>
              ))}
  
            </Stack>
          </Box>
        </div>
        <Box className="print-section mt-2 mb-2">
          <Table className="border-collapse border border-slate-500 mt-5 print-container">
            <Thead>
              <Tr className="text-xs font-serif">
                <th className="border border-slate-600 p-1.5 text-nowrap">GIỚI TÍNH</th>
                <th className="border border-slate-600 p-1.5 text-nowrap">HỌ VÀ TÊN</th>
                <th className="border border-slate-600 p-1.5 text-nowrap">PHÁP DANH</th>
                <th className="border border-slate-600 p-1.5 text-nowrap">NĂM SINH</th>
                <th className="border border-slate-600 p-1.5 text-nowrap">TUỔI</th>
                <th className="border border-slate-600 p-1.5 text-nowrap">SAO</th>
              </Tr>
            </Thead>
            <Tbody>
              {zodiac.map((member, index) => (
                <Tr key={index} className="text-sm capitalize print-page">
                  <td className="border border-slate-700 p-1 px-3 text-center">
                    {member?.gender === 'male' ? 'Nam' : 'Nữ'}
                  </td>
                  <td className="border border-slate-700 p-1 px-3">{member?.name || ''}</td>
                  <td className="border border-slate-700 p-1 px-3">{member?.nickName || ''}</td>
                  <td className="border border-slate-700 p-1 px-3 text-nowrap">{member?.birthdayAndZodiac || ''}</td>
                  <td className="border border-slate-700 p-1 px-3 text-center">{member?.age || ''}</td>
                  <td className="border border-slate-700 p-1 px-3 text-nowrap">{member?.star || ''}</td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box className="flex justify-between">
        <Box cursor='pointer' onClick={() => {handleViewDetails()}} >
          <Text fontWeight='500' className="flex items-center mt-6 text-cyan-600 hover:text-cyan-500" >
            <GrLinkPrevious className="mr-1 text-xs " />
            <span>Trở lại</span>
          </Text>
        </Box>
        <Box className='flex gap-2'>
          <Box
            className="text-xl cursor-pointer bg-blue-600 p-2 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-blue-600 border-2 hover:border-blue-700"
            onClick={handlePrintDocx}
          >
            xuất ra file .Word
          </Box>
          <Box
            className="text-xl cursor-pointer bg-green-600 p-2 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-green-600 border-2 hover:border-green-700"
            onClick={handlePrintExcel}
          >
            xuất ra file .Excel
          </Box>
          <Box
            className="text-xl cursor-pointer bg-cyan-600 p-2 rounded-md px-6 text-gray-50 hover:bg-gray-50 hover:text-cyan-600 border-2 hover:border-cyan-700"
            onClick={handlePrint}
          >
            in phiếu cầu an
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default PrintComponent;
