import { saveAs } from 'file-saver';
import {
  Document,
  Packer,
  Paragraph,
  Table as DocxTable,
  TableCell,
  TableRow,
  TextRun
} from 'docx';

// css
const generateTableCell = (text, widthPercent, bold = false, alignment = 'center') => {
  return new TableCell({
    children: [new Paragraph({ text, bold, alignment })],
    width: {
      size: widthPercent,
      type: 'pct',
      font: "Times New Roman",
    },
    margins: {
      top: 100,
      bottom: 100,
      left: 100,
      right: 100,
    },
  });
};
//css
const generateTextParagraphs = (lines) => {
  const textParagraphs = lines.map(line => (
    new Paragraph({
      children: [
        new TextRun({
          text: `${line}`,
          font: "Times New Roman",
        }),
        new TextRun({
          text: "\n", 
          break: true,
        }),
      ],
      spacing: {
        after: 10,
      },
    })
  ));
  return textParagraphs;
};

export const generateDocx = (family, zodiac) => {
  const owner = zodiac.find(member => member._id === family.onwerId) || {};
  const doc = new Document({
    sections: [{
      children: [
        new Paragraph({
          alignment: 'center',
          spacing: { after: 200 },
          children: [
            new TextRun({
              text: "DÂNG LỄ CẦU AN",
              bold: true,
              size: 32,
              font: "Times New Roman",
            }),
          ],
        }),

        ...generateTextParagraphs([
          `Chủ bái: ${owner.name || ''}`,
          `Pháp danh: ${owner.nickName || ''}`,
          `Nguyên quán: ${owner.homeTown || ''}`,
          `Địa chỉ: ${owner.address || ''}`,
        ]),

        new DocxTable({
          width: {
            size: 100,
            type: 'pct',
          },
          alignment: 'center',
          rows: [
            new TableRow({
              children: [
                generateTableCell('GIỚI TÍNH', 20, true, 'center'),
                generateTableCell('HỌ VÀ TÊN', 20, true, 'center'),
                generateTableCell('PHÁP DANH', 20, true, 'center'),
                generateTableCell('NĂM SINH', 20, true, 'center'),
                generateTableCell('TUỔI', 10, true, 'center'),
                generateTableCell('SAO', 10, true, 'center'),
              ],
            }),
            ...zodiac.map(member => (
              new TableRow({
                children: [
                  generateTableCell(member.gender === 'male' ? 'Nam' : 'Nữ'|| '', 20, false, 'center'),
                  generateTableCell(member.name || '', 20, false, 'center'),
                  generateTableCell(member.nickName || '', 20, false, 'center'),
                  generateTableCell(member.birthdayAndZodiac || '', 20, false, 'center'),
                  generateTableCell(member.age.toString() || '', 10, false, 'center'),
                  generateTableCell(member.star || '', 10, false, 'center'),
                ],
              })
            )),
          ],
        }),
      ],
    }],
  });

  Packer.toBlob(doc).then(blob => {
    saveAs(blob, "Phiếu-cầu-an.docx");
  });
};
