import { FormLabel, Select, Text } from "@chakra-ui/react";
import { SELECT_FIELD } from "utils/constants";

// chọn chùa
export const  SelectField = ({ label, name, options, register, errors, required }) => {
  return (
    <div className="sm:col-span-2">
      <FormLabel htmlFor={name}>{label}{required && <span className="text-red-500">*</span>}</FormLabel>
      <Select
        {...register(name, { required: SELECT_FIELD })}
        id={name}
      >
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </Select>
      {errors[name] && <Text color="red.500">{errors[name].message}</Text>}
    </div>
  );
}